import Service from '../Service/Service';
import axios from "axios";

export default class CloudApiService extends Service {

  private casanaCloudApiUrl: string;

  constructor(authContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/setup-wizard/`;
  }

  /**
   * Retrieve seat details from the Cloud API via the seat's serial number.
   *
   * @param serialNumber
   */
  public getSeat(serialNumber: string): any {
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(this.casanaCloudApiUrl + 'seat/' + serialNumber, config).then((response) => {
      return {
        serial_number: response.data.serial_number,
        firmware_version: response.data.firmware_version,
        hardware_version: response.data.hardware_version,
        seat_id: response.data.seat_id,
        target_firmware: response.data.target_firmware,
        ble_auth_token: response.data.ble_auth_token,
        assigned_users: response.data.assigned_users,
        assigned_seat_user_name: this.extractSeatUserDetail(response.data.assigned_seat_users, 'name'),
        assigned_seat_user_id: this.extractSeatUserDetail(response.data.assigned_seat_users, 'id'),
      }
    })
    .catch(function (error) {
      console.error(error);
    });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn off auto recording and auto uploads.
   *
   * @param seatId
   */
  public disableAutoRecordingAndUpload(seatId: number): any{
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data = {
      automatic_recording: false,
      automatic_upload: false
    }

    return axios.put(this.casanaCloudApiUrl + 'seatconfig/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
      .catch(function (error) {
        console.error(error);
        return {
          success: false,
        }
      });
  }

  /**
   * Update the Seat entity on the Casana Cloud via the API and turn on auto recording and auto uploads.
   *
   * @param seatId
   */
  public enableAutoRecordingAndUpload(seatId: string): any{
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let data = {
      automatic_recording: true,
      automatic_upload: true
    }

    return axios.put(this.casanaCloudApiUrl + 'seatconfig/' + seatId, data, config).then(() => {
      return {
        success: true,
      }
    })
    .catch(function (error) {
      console.error(error);
      return {
        success: false,
      }
    });
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the seat_id for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return int
   */
  public extractSeatUserDetail(assignedSeatUsers: any, field: string) {

    // TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    // TODO: it still works
    if(assignedSeatUsers.length > 0) {
      if ( field === 'id' ) {
        return parseInt(assignedSeatUsers[0]['seat_user_id']);
      } else {
        return assignedSeatUsers[0]['name'];
      }
    }
  }

}
