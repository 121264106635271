/**
 * A base service class
 */
import {Token} from "../../types/Token";

export default class Service {

  protected token: Token;

  public constructor(authContext: any){
    let jwt: string;
    this.token = authContext;

    if(authContext.jwt){
      jwt = authContext.jwt
    } else if(localStorage.getItem('casana-api-jwt')){
      authContext.jwt = localStorage.getItem('casana-api-jwt') ?? '';
      jwt = authContext.jwt;
    } else {
      jwt = '';
    }

    this.setJwt(jwt);
    this.setHeaders(this.getJwt());
  }

  public setJwt(jwt: string): void {
    this.token.jwt = jwt;
    this.token.headers.bearer = `Bearer ${jwt}`;
  }

  public getJwt(): string {
    return this.token.jwt ?? '';
  }

  public setHeaders(jwt: string): void {
    if(this.token){
      this.token.headers.bearer = `Bearer ${jwt}`;
      this.token.headers.accept = 'application/json';
    }
  }

  public getBearerHeader(): string {
    if(this.token.headers.bearer){
      return this.token.headers.bearer;
    } else {
      return '';
    }
  }

  public getAcceptHeader(): string {
    if(this.token.headers.accept){
      return this.token.headers.accept;
    } else {
      return '';
    }
  }
}
