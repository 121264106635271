import React, {ReactNode, useEffect, useState} from 'react';
import {IonCard, IonContent, IonPage} from '@ionic/react';
import PairPinScreen from "../../components/Pair/PairPinScreen";
import PairSerialScreen from "../../components/Pair/PairSerialScreen";

interface ContainerProps {
  toggleDebug: Function
}

const Pair:React.FC<ContainerProps> = ({toggleDebug}) => {
  const handlePinView= async () => {
    setPairView(
      <PairPinScreen
        toggleDebug={toggleDebug}
        handleSerialView={handleSerialView}
      />
    );
  }

  const handleSerialView= async () => {
    setPairView(
      <PairSerialScreen
        toggleDebug={toggleDebug}
        handlePinView={handlePinView}
      />
    );
  }

  const [pairView, setPairView] = useState<ReactNode>(
    <PairSerialScreen
      toggleDebug={toggleDebug}
      handlePinView={handlePinView}
    />
  );

  useEffect(() => {
    document.title = "Pair to a seat via Bluetooth";
  }, []);

  return (
    <IonPage>
      <IonContent fullscreen className="container">
        <IonCard className="standard-container">
          {pairView}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Pair;
