import React, {createContext, useCallback, useState} from 'react';
import {Patient} from "../../types/Patient";

interface PatientDataInterface {
  data: Patient,
  parsePatientData: Function,
}

export const PatientDataContext = createContext<PatientDataInterface>({
  'data': {
    'assigned_seats': {},
    'assigned_seat_id': 0,
    'assigned_seat_serial_number': '',
    'seat_user_id': 0,
    'cell_phone': '',
    'email': '',
    'first_name': '',
    'last_name': '',
    'date_of_birth': '',
    'monitored_since': '',
    'address': '',
    'address_other': '',
    'city': '',
    'state': '',
    'zip_code': '',
    'country': '',
    'sex_assigned_at_birth': '',
    'seat_user_calibrations': {
      'seat_user_calibration_id': 0,
      'height_feet': 0,
      'height_inches': 0,
      'sternal_notch': 0
    },
  },
  parsePatientData: (patientData: Patient) => {} ,
});

export const PatientProvider = ({ children } : React.HTMLAttributes<Element>) => {
  let [patient] = useState<Patient>({
    'assigned_seats': {},
    'assigned_seat_id': 0,
    'assigned_seat_serial_number': '',
    'seat_user_id': 0,
    'cell_phone': '',
    'email': '',
    'first_name': '',
    'last_name': '',
    'date_of_birth': '',
    'monitored_since': '',
    'address': '',
    'address_other': '',
    'city': '',
    'state': '',
    'zip_code': '',
    'country': '',
    'sex_assigned_at_birth': '',
    'seat_user_calibrations': {
      'seat_user_calibration_id': 0,
      'height_feet': 0,
      'height_inches': 0,
      'sternal_notch': 0
    },
  });

  const parsePatientData = (patientData: Patient) => {

    if (patientData.assigned_seat_id) {
      patient.assigned_seat_id = patientData.assigned_seat_id
    }

    if (patientData.assigned_seat_serial_number) {
      patient.assigned_seat_serial_number = patientData.assigned_seat_serial_number
    }

    if(patientData.address){
      patient.address = patientData.address
    }

    if (patientData.address_other) {
      patient.address_other = patientData.address_other
    }

    if (patientData.cell_phone) {
      patient.cell_phone = patientData.cell_phone
    }

    if (patientData.city) {
      patient.city = patientData.city
    }

    if (patientData.country) {
      patient.country = patientData.country
    }

    if (patientData.date_of_birth) {
      patient.date_of_birth = patientData.date_of_birth
    }

    if (patientData.email) {
      patient.email = patientData.email
    }

    if (patientData.first_name) {
      patient.first_name = patientData.first_name
    }

    if (patientData.last_name) {
      patient.last_name = patientData.last_name
    }

    if (patientData.monitored_since) {
      patient.monitored_since = patientData.monitored_since
    }

    if (patientData?.seat_user_calibrations?.height_feet) {
      patient.seat_user_calibrations.height_feet = patientData.seat_user_calibrations.height_feet
    }

    if (patientData?.seat_user_calibrations?.height_inches) {
      patient.seat_user_calibrations.height_inches = patientData.seat_user_calibrations.height_inches
    }

    if (patientData?.seat_user_calibrations?.sternal_notch) {
      patient.seat_user_calibrations.sternal_notch = patientData.seat_user_calibrations.sternal_notch
    }

    if (patientData.seat_user_id) {
      patient.seat_user_id = patientData.seat_user_id
    }

    if (patientData.state) {
      patient.state = patientData.state
    }

    if (patientData.zip_code) {
      patient.zip_code = patientData.zip_code
    }

    if (patientData.sex_assigned_at_birth) {
      patient.sex_assigned_at_birth = patientData.sex_assigned_at_birth
    }

    if (patientData?.seat_user?.seat_user_calibrations) {
      patient.seat_user_calibrations = patientData.seat_user.seat_user_calibrations
    }
  }

  const contextValue = {
    data: patient,
    parsePatientData: useCallback((patientData: Patient) => parsePatientData(patientData), [])
  };

  return (
    <PatientDataContext.Provider value={contextValue}>
      {children}
    </PatientDataContext.Provider>
  );
};
