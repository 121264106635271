import React, {useContext, useState} from "react";
import AuthContext from "../Auth/AuthContext";
import InitializationService from "../../services/InitializationService/InitializationService";
import usePatientData from "../PatientDetails/PatientHook";
import useInitializationData from "./InitializationHook";
import {
  IonButton,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonCardContent,
  IonLabel,
  IonItem,
  IonProgressBar,
  IonCol,
  IonNote,
  IonRow,
  IonFooter
} from '@ionic/react';


interface ContainerProps {
  handleInitializationSuccess: any;
  handleBackToStart: any;
  setBanner: any;
  clearBanner: any;
  handleInitializationFailure: any;
}

const InitializeFormScreen: React.FC<ContainerProps> = ({
  handleInitializationSuccess,
  handleBackToStart,
  setBanner,
  clearBanner,
  handleInitializationFailure
}) => {
  const init = useInitializationData();
  const patient = usePatientData();
  const authContext = useContext(AuthContext);
  const initService = new InitializationService(authContext);

  const [systolicBp1, setSystolicBp1] = useState(0);
  const [diastolicBp1, setDiastolicBp1] = useState(0);
  const [systolicBp2, setSystolicBp2] = useState(0);
  const [diastolicBp2, setDiastolicBp2] = useState(0);
  const [systolicBp3, setSystolicBp3] = useState(0);
  const [diastolicBp3, setDiastolicBp3] = useState(0);
  const [weight, setWeight] = useState(0);
  const [isSystolic1Valid, setIsSystolic1Valid] = useState<boolean>(true);
  const [isDiastolic1Valid, setIsDiastolic1Valid] = useState<boolean>(true);
  const [isSystolic2Valid, setIsSystolic2Valid] = useState<boolean>(true);
  const [isDiastolic2Valid, setIsDiastolic2Valid] = useState<boolean>(true);
  const [isSystolic3Valid, setIsSystolic3Valid] = useState<boolean>(true);
  const [isDiastolic3Valid, setIsDiastolic3Valid] = useState<boolean>(true);
  const [isWeightValid, setIsWeightValid] = useState<boolean>(true);
  const [isPolling, setIsPolling] = useState(false);

  /**
   * Handle setting the diastolic error states correctly.
   */
  const setSystolicInvalid = () => {
    if (systolicBp1 && systolicBp1 >= 30 && systolicBp1 <= 240) {
      setIsSystolic1Valid(true);
    } else {
      setIsSystolic1Valid(false);
    }

    if (systolicBp2 && systolicBp2 >= 30 && systolicBp2 <= 240) {
      setIsSystolic2Valid(true);
    } else {
      setIsSystolic2Valid(false);
    }

    if (systolicBp3 && systolicBp3 >= 30 && systolicBp3 <= 240) {
      setIsSystolic3Valid(true);
    } else {
      setIsSystolic3Valid(false);
    }
  }

  /**
   * Handle setting the diastolic error states correctly.
   */
  const setDiastolicInvalid = () => {
    if (diastolicBp1 && diastolicBp1 >= 30 && diastolicBp1 <= 240) {
      setIsDiastolic1Valid(true);
    } else {
      setIsDiastolic1Valid(false);
    }

    if (diastolicBp2 && diastolicBp2 >= 30 && diastolicBp2 <= 240) {
      setIsDiastolic2Valid(true);
    } else {
      setIsDiastolic2Valid(false);
    }

    if (diastolicBp3 && diastolicBp3 >= 30 && diastolicBp3 <= 240) {
      setIsDiastolic3Valid(true);
    } else {
      setIsDiastolic3Valid(false);
    }
  }

  /**
   * Handle the form validation checking.
   */
  const formIsValid = (): boolean => {
    let systolicValid: boolean;
    let diastolicValid: boolean;
    let weightValid: boolean;

    if (
      diastolicBp1 && diastolicBp2 && diastolicBp3 &&
      diastolicBp1 >= 30 && diastolicBp3 >= 30 && diastolicBp3 >= 30 &&
      diastolicBp1 <= 240 && diastolicBp2 <= 240 && diastolicBp3 <= 240
    ) {
      setIsDiastolic1Valid(true);
      setIsDiastolic2Valid(true);
      setIsDiastolic3Valid(true);
      diastolicValid = true
    } else {
      setDiastolicInvalid()
      diastolicValid = false;
    }

    if (
      systolicBp1 && systolicBp2 && systolicBp3 &&
      systolicBp1 >= 30 && systolicBp2 >= 30 && systolicBp3 >= 30 &&
      systolicBp1 <= 240 && systolicBp2 <= 240 && systolicBp3 <= 240
    ) {
      setIsSystolic1Valid(true);
      setIsSystolic2Valid(true);
      setIsSystolic3Valid(true);
      systolicValid = true
    } else {
      setSystolicInvalid()
      systolicValid = false;
    }

    if (weight && weight > 0 && weight <= 999) {
      setIsWeightValid(true);
      weightValid = true;
    } else {
      setIsWeightValid(false);
      weightValid = false;
    }

    return diastolicValid && systolicValid && weightValid;
  }

  const submitInit = () => {
    clearBanner();

    if (!formIsValid()) {
      setBanner(false, 'There are errors with your initialization values')
      return;
    }

    setIsPolling(true);

    init.data.bp_systolic_1 = systolicBp1;
    init.data.bp_diastolic_1 = diastolicBp1;
    init.data.bp_systolic_2 = systolicBp2;
    init.data.bp_diastolic_2 = diastolicBp2;
    init.data.bp_systolic_3 = systolicBp3;
    init.data.bp_diastolic_3 = diastolicBp3;
    init.data.weight = weight;
    init.data.age = getPatientAge(patient.data.date_of_birth);
    init.data.sex_assigned_at_birth = patient.data.sex_assigned_at_birth
    init.data.height_feet = patient.data.seat_user_calibrations.height_feet;
    init.data.height_inches = patient.data.seat_user_calibrations.height_inches;
    init.data.sternal_notch = patient.data.seat_user_calibrations.sternal_notch;

    let postData = Object.assign({}, init.data);

    delete postData.date_of_birth;
    delete postData.sit_start_time;
    delete postData.errors;

    initService.submitInit(postData).then(async (response: any) => {
      if (!response.success) {
        let error = response.error;
        setBanner(false, 'There was an error with your submission: ' + error);
        setIsPolling(false);
        return;
      }

      let token = response.data.init_token;

      let statusCheck = window.setInterval(function () {
        initService.getInitStatus(token).then(async (response: any) => {
          let status = response.status;

          if (status === 'success') {
            clearInterval(statusCheck);
            handleStatusSucceeded();
          }

          if (status === 'failed') {
            clearInterval(statusCheck);
            init.data.errors = response.errors;
            handleStatusFailed();
          }

        });
      }, 5000);

    }).catch((error: any) => {
      console.error(error);
    });
  }

  const handleStatusSucceeded = () => {
    setBanner(true, 'Initialization job completed successfully');
    setIsPolling(false);
    handleInitializationSuccess();
  }

  const handleStatusFailed = () => {
    setIsPolling(false);
    setBanner(false, 'Initialization job failed due to the following errors.');
    handleInitializationFailure();
  }

  const getPatientAge = (dateOfBirth: string | undefined): number => {
    if(!dateOfBirth){
      return 20;
    }

    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Enter initialization information</IonCardTitle>
        {isPolling ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
      </IonCardHeader>
      <IonCardContent className="init-form casana-form initialize-screen">
        <IonRow className="blood-pressure-row">
          <IonCol size="3" size-md="4" className="ion-text-right">Blood Pressure 1:</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isSystolic1Valid && 'ion-valid'} ${!isSystolic1Valid && 'ion-invalid'}`}>
              <IonInput id="bp-sys-1"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setSystolicBp1(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isDiastolic1Valid && 'ion-valid'} ${!isDiastolic1Valid && 'ion-invalid'}`}>
              <IonInput id="bp-dia-1"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setDiastolicBp1(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="2" size-md="3"></IonCol>
        </IonRow>
        <IonRow className="blood-pressure-row">
          <IonCol size="3" size-md="4" className="ion-text-right">Blood Pressure 2:</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isSystolic2Valid && 'ion-valid'} ${!isSystolic2Valid && 'ion-invalid'}`}>
              <IonInput id="bp-sys-2"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setSystolicBp2(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isDiastolic2Valid && 'ion-valid'} ${!isDiastolic2Valid && 'ion-invalid'}`}>
              <IonInput id="bp-dia-2"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setDiastolicBp2(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="2" size-md="3"></IonCol>
        </IonRow>
        <IonRow className="blood-pressure-row">
          <IonCol size="3" size-md="4" className="ion-text-right">Blood Pressure 3:</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isSystolic3Valid && 'ion-valid'} ${!isSystolic3Valid && 'ion-invalid'}`}>
              <IonInput id="bp-sys-3"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setSystolicBp3(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="1" size-md="1" className="ion-text-center">/</IonCol>
          <IonCol size="3" size-md="2">
            <IonItem className={`${isDiastolic3Valid && 'ion-valid'} ${!isDiastolic3Valid && 'ion-invalid'}`}>
              <IonInput id="bp-dia-3"
                        placeholder=""
                        type="number"
                        min={30}
                        max={240}
                        onIonChange={(e: any) => setDiastolicBp3(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 30 and 240</IonNote>
            </IonItem>
          </IonCol>
          <IonCol size="2" size-md="3"></IonCol>
        </IonRow>
        <IonRow className="blood-pressure-row">
          <IonCol size="3" size-md="4" className="ion-text-right">Weight:</IonCol>
          <IonCol size="3" size-md="5">
            <IonItem className={`${isWeightValid && 'ion-valid'} ${!isWeightValid && 'ion-invalid'}`}>
              <IonInput id="weight"
                        placeholder="Weight in lbs"
                        type="number"
                        min={1}
                        max={999}
                        onIonChange={(e: any) => setWeight(e.detail.value)}
              ></IonInput>
              <IonNote slot="error">Must be between 1 and 999 lbs</IonNote>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow className="m-t-20">
          <IonCol size="3" size-md="3"></IonCol>
          <IonCol size="6" size-md="6">
            {isPolling ? null : <IonButton className="initialize-form-submit" onClick={submitInit}>Submit</IonButton>}
          </IonCol>
          <IonCol size="2" size-md="3"></IonCol>
        </IonRow>
      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-back ion-float-right" onClick={handleBackToStart}>
          Back
        </IonButton>
      </IonFooter>
    </>
  );
};

export default InitializeFormScreen;
