import React, {useCallback, useContext, useState} from 'react';
import {SeatSettings} from "../../types/SeatSettings";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import {useHistory} from "react-router-dom";

export const SeatSettingsContext = React.createContext({
  settings: {
    batteryVoltage: 0,
    bootReason: 0,
    bootloaderVersion: 0,
    firmwareAction: 0,
    firmwareActionAttempts: 2,
    firmwareStatus: 0,
    firmwareVersion: '',
    recordingCount: 0,
    runtime: 0,
    serialNumber: '',
    timeOfLastCheckin: '',
    timeOfLastRecording: '',
    utcTimestamp: 0,
    wifi_ssid: '',
    wifi_pass:  '',
    cloud_endpoint: '',
    assignedSeatUserId: 0,
    assignedSeatUserName: '',
  },
  buttonClass: 'off',
  parseSettings: (settings: SeatSettings) => {},
  handleViewChange: () => {},
  setButtonClass:  (buttonClass: string) => {}
});

export default function SeatSettingsProvider({children}:{children:React.ReactNode}){
  const locationContext = useContext<LocationData>(AppLocationContext);
  const history = useHistory();
  const [batteryVoltage, setBatteryVoltage] = useState(0);
  const [bootReason, setBootReason] = useState(0);
  const [bootloaderVersion, setBootloaderVersion] = useState(0);
  const [firmwareAction, setFirmwareAction] = useState(0);
  const [firmwareActionAttempts, setFirmwareActionAttempts] = useState(2);
  const [firmwareStatus, setFirmwareStatus] = useState(0);
  const [firmwareVersion, setFirmwareVersion] = useState('');
  const [recordingCount, setRecordingCount] = useState(0);
  const [runtime, setRuntime] = useState(0);
  const [serialNumber, setSerialNumber] = useState('');
  const [timeOfLastCheckin, setTimeOfLastCheckin] = useState('');
  const [timeOfLastRecording, setTimeOfLastRecording] = useState('');
  const [utcTimestamp, setUtcTimestamp] = useState(0);
  const [wifiSsid, setWifiSsid] = useState('');
  const [wifiPass, setWifiPass] = useState('');
  const [cloudEndpoint, setCloudEndpoint] = useState('');
  const [assignedSeatUserId, setAssignedSeatUserId] = useState(0);
  const [assignedSeatUserName, setAssignedSeatUserName] = useState('');
  const [buttonClass, setButtonClass] = useState<string>('off');

  function handleViewChange() {
    if(window.location.pathname !== '/seat-settings'){
      locationContext.returnView = window.location.pathname;
      history.push('/seat-settings');
      setButtonClass('on');
    } else {
      history.push(locationContext.returnView);
      setButtonClass('off');
    }
  }

  /**
   * Parse the data from the seat into the context.
   *
   * @param seatSettings
   */
  const parseSettings = (seatSettings: SeatSettings) => {
    if(seatSettings.batteryVoltage){
      setBatteryVoltage(seatSettings.batteryVoltage);
    }

    if(seatSettings.bootReason){
      setBootReason(seatSettings.bootReason);
    }

    if(seatSettings.bootloaderVersion){
      setBootloaderVersion(seatSettings.bootloaderVersion);
    }

    if(seatSettings.firmwareAction){
      setFirmwareAction(seatSettings.firmwareAction);
    }

    if(seatSettings.firmwareActionAttempts){
      setFirmwareActionAttempts(seatSettings.firmwareActionAttempts);
    }

    if(seatSettings.firmwareStatus){
      setFirmwareStatus(seatSettings.firmwareStatus);
    }

    if(seatSettings.firmwareVersion){
      setFirmwareVersion(seatSettings.firmwareVersion);
    }

    if(seatSettings.recordingCount){
      setRecordingCount(seatSettings.recordingCount);
    }

    if(seatSettings.runtime){
      setRuntime(seatSettings.runtime);
    }

    if(seatSettings.serialNumber){
      setSerialNumber(seatSettings.serialNumber);
    }

    if(seatSettings.timeOfLastCheckin){
      let dateTime = Date.now() - parseInt(seatSettings.timeOfLastCheckin);
      let date = new Date(dateTime).toDateString()
      let time = new Date(dateTime).toTimeString()
      setTimeOfLastCheckin(date + ' ' + time);
    }

    if(seatSettings.timeOfLastRecording){
      let dateTime = Date.now() - parseInt(seatSettings.timeOfLastRecording);
      let date = new Date(dateTime).toDateString()
      let time = new Date(dateTime).toTimeString()
      setTimeOfLastRecording(date + ' ' + time);
    }

    if(seatSettings.utcTimestamp){
      setUtcTimestamp(seatSettings.utcTimestamp);
    }

    if(seatSettings.wifi_ssid){
      setWifiSsid(seatSettings.wifi_ssid);
    }

    if(seatSettings.wifi_pass){
      setWifiPass(seatSettings.wifi_pass);
    }

    if(seatSettings.cloud_endpoint){
      setCloudEndpoint(seatSettings.cloud_endpoint);
    }

    if(seatSettings.assigned_seat_user_id){
      setAssignedSeatUserId(seatSettings.assigned_seat_user_id);
    }

    if(seatSettings.assigned_seat_user_name){
      setAssignedSeatUserName(seatSettings.assigned_seat_user_name);
    }
  }

  const contextValue = {
    settings: {
      'batteryVoltage' : batteryVoltage,
      'bootReason' : bootReason,
      'bootloaderVersion' : bootloaderVersion,
      'firmwareAction' : firmwareAction,
      'firmwareActionAttempts' : firmwareActionAttempts,
      'firmwareStatus' : firmwareStatus,
      'firmwareVersion' : firmwareVersion,
      'recordingCount' : recordingCount,
      'runtime' : runtime,
      'serialNumber' : serialNumber,
      'timeOfLastCheckin' : timeOfLastCheckin,
      'timeOfLastRecording' : timeOfLastRecording,
      'utcTimestamp' : utcTimestamp,
      'wifi_ssid' : wifiSsid,
      'wifi_pass' : wifiPass,
      'cloud_endpoint' : cloudEndpoint.split('.')[0],
      'assignedSeatUserName' : assignedSeatUserName,
      'assignedSeatUserId' : assignedSeatUserId,
    },
    buttonClass: buttonClass,
    parseSettings: useCallback((seatSettings:SeatSettings) => parseSettings(seatSettings),[]),
    handleViewChange: useCallback(() => handleViewChange(),[]),
    setButtonClass: useCallback((buttonClass: string) => setButtonClass(buttonClass), [])
  };

  return (
    <SeatSettingsContext.Provider value={contextValue}>
      {children}
    </SeatSettingsContext.Provider>
  );
}
