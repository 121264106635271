import './PatientDetailsScreens.css';
import React, {useEffect, useState} from 'react';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonGrid, IonRow, IonCol,
  IonCardTitle, IonFooter, IonText, IonContent
} from '@ionic/react';
import usePatientData from "./PatientHook";
import useSeatSettingsData from "../SeatSettings/SeatSettingsHook";

interface ContainerProps {
  handeCurrentPatient: Function,
  handleSelectView: Function
}

const ConfirmSeatUserScreen: React.FC<ContainerProps> = ({ handeCurrentPatient, handleSelectView }) => {

  const patient = usePatientData();
  const seatSettings = useSeatSettingsData();

  const [isPatientPairedWithOtherSeat, setIsPatientPairedWithOtherSeat] = useState(false);

  useEffect(() => {
    document.title = "Confirm Patient Name";
    if(
      patient.data.assigned_seat_serial_number !== '' &&
      patient.data.assigned_seat_serial_number !== seatSettings.settings.serialNumber)
    {
      setIsPatientPairedWithOtherSeat(true);
    }
  }, [patient.data, seatSettings.settings, isPatientPairedWithOtherSeat]);

  const pairCurrentPatient = () => {
    handeCurrentPatient()
  }

  const selectNewId = () => {
    handleSelectView()
  }

  return (
    <>
      <IonContent className="container">
        <IonCard className="standard-container">
          <IonCardHeader>
            <IonCardTitle className="m-b-20">Pair seat with this Patient?</IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <IonGrid className="seat-user-id-form">
              <IonRow>
                <IonCol size="12" size-sm="6">
            <IonCardContent className="seat-user-confirmation currently-paired-patient" onClick={ pairCurrentPatient }>
              <IonText className="patient-name md">{patient.data.first_name} {patient.data.last_name}</IonText>
              <IonText>[{patient.data.seat_user_id}]</IonText>
            </IonCardContent>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={ pairCurrentPatient }>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right" onClick={ selectNewId }>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>
  );
};

export default ConfirmSeatUserScreen;
