import axios from 'axios';
import Service from '../Service/Service';
import {Token} from "../../types/Token";
import jwtDecode from "jwt-decode";

export default class AuthenticationService extends Service {

  private loginURL: string;
  private logoutURL: string;
  private heartbeatURL: string;

  constructor(authContext: any) {
    super(authContext);
    this.loginURL = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`;
    this.logoutURL = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/logout`;
    this.heartbeatURL = `${process.env.REACT_APP_BASE_URL}/api/v1/auth/pulse`;
  }

  /**
   * Log the user in and setup token and header ata correctly. Also enables the heartbeat pulse.
   *
   * @param email
   * @param password
   */
  public login(email: string, password: string): any{
    this.token.username = email;

    let data = {email: this.token.username, password: password}
    let config = {headers: {'Accept': this.token.headers?.accept}}

    return axios.post(this.loginURL, data, config).then((response) => {
        this.setJwt(response.data.token);
        window.pulseHeartbeatInterval = this.pulse();
        this.token.jwt = response.data.token
        return {success: true, data: {jwt: response.data.token}}
      })
      .catch(function (error) {
        window.clearInterval(window.pulseHeartbeatInterval)
        localStorage.removeItem('casana-api-jwt');

        let retVal = {
          success: false,
          data: {}
        }

        if (error.response) {
          retVal.data = {
            errors: error.response.statusText,
            status_code: error.response.status
          }
        }

        return retVal;
      });
  }

  /**
   * Logout and clear all token data from the class. Also disables the heartbeat pulse.
   */
  public logout(): any{
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(this.logoutURL, config).then(() => {
      window.clearInterval(window.pulseHeartbeatInterval);
      window.clearInterval(window.blePulseHeartbeatInterval);
      localStorage.removeItem('casana-api-jwt');
      this.token.jwt = '';
      this.token.username = '';
    }).catch(function (error) {
      window.clearInterval(window.pulseHeartbeatInterval)
      localStorage.removeItem('casana-api-jwt');
      console.error(error)
    });
  }

  /**
   * Calls the heartbeat endpoint with the correct headers.
   */
  public heartbeat(): any{
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(
        this.heartbeatURL,
        config
      ).then(function () {
        return {success: true, data: {authenticated: true}}
      }).catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.message,
            status_code: error.status_code
          }
        }
      }
    )};

  /**
   * Set up the interval on the global window so that it is available to the entire application and can be triggered
   * or cleared from anywhere. This is required due to React / Ionic's scoping of components. interval is defined
   * by the check_interval value on the token which is currently set to 10 seconds.
   */
    public pulse(): any{
      let tokenData: Token | null = this.token.jwt ? jwtDecode(this.token.jwt) : null;

    return window.setInterval(() => {
        this.heartbeat().then((response:any) => {
          if(!response.success){
            this.logout().then(() =>{
              window.location.reload();
            })
          }
        })
      // @ts-ignore
      }, tokenData ? tokenData.check_interval * 1000 : 10000)
    };
}
