import './SeatSettingsIcon.css'
import React from 'react';
import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {closeOutline, constructSharp} from "ionicons/icons";
import useSeatSettings from "./SeatSettingsHook";


interface ViewSettings {
  display: boolean;
}

const SeatSettingsIcon: React.FC<ViewSettings> = ({display}) => {
  const ssc = useSeatSettings();

  const handleClick = () => {
    ssc.handleViewChange()
  }

  return (
    <IonItem
      className={display ? 'ts ion-show ' + ssc.buttonClass : 'ts ion-hide ' + ssc.buttonClass}
      lines="none"
      onClick={handleClick}
    >
      <IonLabel>
        {
          ssc.buttonClass === 'off' ?
          <IonIcon slot="content" ios={constructSharp} md={constructSharp} className={ssc.buttonClass}></IonIcon>
          :
          <IonIcon slot="content" ios={closeOutline} md={closeOutline} className={ssc.buttonClass}></IonIcon>
        }
      </IonLabel>
    </IonItem>
  );
};

export default SeatSettingsIcon;
