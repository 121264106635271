import React, {useContext, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from "../../components/Auth/AuthContext";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import PairContext from "../../components/Pair/PairContext";
import {Seat} from "../../types/Seat";
import AppLocationContext from "../../components/Includes/AppLocationContext";

interface DebugSettings {
  toggleDebug: Function;
}

const Logout: React.FC<DebugSettings> = ({toggleDebug}) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const pairContext = useContext(PairContext);
  const appLocationContext = useContext(AppLocationContext);
  const auth = new AuthenticationService(authContext);
  const api = new CloudApiService(authContext);

  const logout = () => {
    toggleDebug(false);
    auth.logout().then(()=>{
      history.push("/login");
      appLocationContext.returnView = '/pair';
    });
  }

  useEffect(() => {
    if(pairContext.serial_number){
      api.getSeat(pairContext.serial_number).then((seatData: Seat) => {
        if(seatData.seat_id){
          api.enableAutoRecordingAndUpload(seatData.seat_id.toString()).then(() => {
            logout();
          });
        } else {
          logout();
        }
      }).catch(() => {
        logout();
      });
    } else {
      logout();
    }
  }, []);

  //Nothing to render, we just need to execute the logout logic and redirect the user.
  return null;
};

export default Logout;
