import './modal.css';
import {
  IonButton,
  IonContent, IonGrid,
  IonModal, IonRow, IonText
} from "@ionic/react";
import useModalError from "./ModalHook";
import {useRef} from "react";

function ModalNotification() {
  const modal = useRef<HTMLIonModalElement>(null);
  const { error, removeError } = useModalError();

  function onWillDismiss() {
    removeError();
  }

  return (
    <IonModal
      ref={modal} onWillDismiss={() => onWillDismiss()}
      isOpen={!!error.message}
      backdrop-dismiss={false}
      keyboard-close={false}
      class="casana-modal"
    >
      <IonContent className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonText>
              <h1>{error.title}</h1>
            </IonText>
          </IonRow>
          <IonRow><br /></IonRow>
          <IonRow>
            <IonText>
              <p>{error.message}</p>
            </IonText>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonButton
        fill="solid"
        strong={true}
        expand="block"
        onClick={() => modal.current?.dismiss()}
        routerLink={error.buttonLocation ? error.buttonLocation : '/'}
      >
        {error.buttonText}
      </IonButton>
    </IonModal>
  )
}

export default ModalNotification
