import axios from 'axios';
import Service from '../Service/Service';
import {Patient} from "../../types/Patient";
import usePatientData from "../../components/PatientDetails/PatientHook";

export default class PatientDetailsService extends Service {

  private casanaCloudApiUrl: string;
  public patient = usePatientData();
  public patientID : number;

  constructor(authContext: any, patientContext: any) {
    super(authContext);
    this.casanaCloudApiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/`;
    let localId = localStorage.getItem('casana-patient-id');
    let localInt = localId ? parseInt(localId) : 0;

    if (localInt !== 0) {
      patientContext.seat_user_id = localInt
    }

    this.patientID = localInt;
  }

  /**
   * Connect to the Casana Cloud to retrieve the Seat User (Patient) information
   *
   * @param seatUserId
   */
  public retrieveSeatUserDetails(seatUserId: number|undefined): any{

    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    return axios.get(`${this.casanaCloudApiUrl}seatuser/${seatUserId}`, config).then((response) => {
      let patient = {
        'email': '',
        'cell_phone': '',
        'first_name': '',
        'last_name': '',
        'date_of_birth': '',
        'monitored_since': '',
        'address': '',
        'address_other': '',
        'city': '',
        'state': '',
        'zip_code': '',
        'country': '',
        'sex_assigned_at_birth': '',
        'seat_user_calibrations': '',
        'assigned_seat_id': 0,
        'assigned_seat_serial_number': '',
      };

      patient.email = response.data.email;
      patient.cell_phone = response.data.cell_phone;
      patient.first_name = response.data.first_name;
      patient.last_name = response.data.last_name;
      patient.date_of_birth = response.data.date_of_birth;
      patient.monitored_since = response.data.monitored_since;
      patient.address = response.data.seat_user_address?.address;
      patient.address_other = response.data.seat_user_address?.address_other;
      patient.city = response.data.seat_user_address?.city;
      patient.state = response.data.seat_user_address?.state;
      patient.zip_code = response.data.seat_user_address?.zip_code;
      patient.country = response.data.seat_user_address?.country ?? 'US';
      patient.sex_assigned_at_birth = response.data.sex_assigned_at_birth;
      patient.seat_user_calibrations = response.data.seat_user_calibrations;
      patient.assigned_seat_id = this.extractSeatIdFromAssignedSeats(response.data.assigned_seats);
      patient.assigned_seat_serial_number = this.extractSerialNumberFromAssignedSeats(response.data.assigned_seats);

      this.patient.parsePatientData(patient);

      return {
          success: true,
          data: {
            seat_user_id: seatUserId,
            seat_user: response.data
          }
        }
      }
    )
    .catch(function (error) {
      return {
        success: false,
        data: {
          errors: error.statusText,
          status_code: error.status
        }
      }
    });
  }

  /**
   * Update the Seat User entity on the Casana Cloud via the API. ONly send address information if we have some of
   * it to send. the backend will tell us that we have to fill everything out if one piece of information is
   * missing.
   */
  public updateSeatUserDetails(patientDetails: Patient): any{
    let config = {
      headers: {
        'Accept': this.token.headers?.accept,
        'Content-Type': this.token.headers?.accept,
        'Authorization': this.token.headers?.bearer
      }
    }

    let seatUserId = patientDetails.seat_user_id;
    let data: any = {
      "first_name": patientDetails.first_name,
      "last_name": patientDetails.last_name,
      "date_of_birth" : patientDetails.date_of_birth,
      "monitored_since" : patientDetails.monitored_since,
      "phone": patientDetails.cell_phone,
      "sex_assigned_at_birth" : patientDetails.sex_assigned_at_birth,
      "height_feet" : patientDetails.seat_user_calibrations?.height_feet
        ? patientDetails.seat_user_calibrations.height_feet
        : 0,
      "height_inches" : patientDetails.seat_user_calibrations?.height_inches
        ? patientDetails.seat_user_calibrations.height_inches
        : 0,
      "sternal_notch" : patientDetails.seat_user_calibrations?.sternal_notch,
      "seat_id" : localStorage.getItem('casana-seat-id')
    }

    if(this.determineIfAddressIsNeeded(patientDetails)){
      data.address = patientDetails.address;
      data.address_other = patientDetails.address_other;
      data.city = patientDetails.city;
      data.state = patientDetails.state;
      data.zip = patientDetails.zip_code;
      data.country = 'US';
    }

    return axios.patch(`${this.casanaCloudApiUrl}seatuser/${seatUserId}`, data, config).then(
      (response) => {
        return {
          success: true,
          data: {
            seat_user_id: seatUserId,
            seat_user: response.data
          }
        }
      })
      .catch(function (error) {
        return {
          success: false,
          data: {
            errors: error.response.data,
            status_code: error.response.status
          }
        }
      });
  }

  /**
   * If none of the address parts are set by the user then we don't need to send the fields in the post
   * request. If we send empty values the backend will complain about them not being set.
   *
   * @param patientDetails
   *
   * @private
   */
  private determineIfAddressIsNeeded(patientDetails: Patient){
    return patientDetails.address !== '' ||
      patientDetails.address_other !== '' ||
      patientDetails.city !== '' ||
      patientDetails.zip_code !== '' ||
      patientDetails.state !== '';
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the seat_id for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return int
   */
  private extractSeatIdFromAssignedSeats(assignedSeats: any) {
    let seatId = '';
    //TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    //TODO: it still works
    if(assignedSeats.length > 0) {
      Object.keys(assignedSeats[0]).forEach(function(key) {
        seatId = key;
      });
      return parseInt(seatId);
    }
    return 0;
  }

  /**
   * This function should take in a value from the API response for assigned_seats
   * Sample: [{"1459":"F20AB7EC88FB"}] and return the serial number for the first
   * value returned even if there is multiple assigned/connected seats.
   * @param assignedSeats
   * @return string
   */
  private extractSerialNumberFromAssignedSeats(assignedSeats: any) {
    let seatSerialNumber = '';
    //TODO: add some defensive programming to make sure if there are no assigned seats or multiple assigned seats
    //TODO: it still works
    if(assignedSeats.length > 0) {
      Object.keys(assignedSeats[0]).forEach(function(key) {
        seatSerialNumber = assignedSeats[0][key];
      });
      return seatSerialNumber;
    }
    return '';
  }
}
