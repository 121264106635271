import React, {useContext, useState} from "react";
import {
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonProgressBar,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import hsi from "../../lib/HeartSeatInterface";
import {Token} from "../../types/Token";
import AuthContext from "../Auth/AuthContext";
import InitializationService from "../../services/InitializationService/InitializationService";
import usePatientData from "../PatientDetails/PatientHook";
import useInitializationData from "./InitializationHook";


interface ContainerProps {
  handleFormScreenLink: any;
  handleCancelConfirmRecording: any;
  setBanner: any;
}

const InitializeSitTimerConfirmScreen: React.FC<ContainerProps> = ({handleFormScreenLink, handleCancelConfirmRecording, setBanner}) => {

  const init = useInitializationData();
  const patient = usePatientData();
  const authContext = useContext<Token>(AuthContext);
  const initService = new InitializationService(authContext);
  const [sitSessionData, setSitSessionData] = useState([{sit_session_id: 0, sit_start_time: ''}]);
  const [isUploadingRecording, setIsUploadingRecording] = useState(false);

  const uploadRecording = async () => {
    setIsUploadingRecording(true);
    /* get the most up-to-date last checkin time */
    let newStatus = await hsi.handleCmd('get_status', null);
    const oldCheckin = newStatus.timeOfLastCheckin;

    /* tell the seat to check in at the next available opportunity (async) */
    await hsi.handleCmd('checkin_with_upload', null);

    /* poll the last checkin time to see if we have had a successfully checkin */
    for (let i = 0; i < 600; i++) { // @todo determine longest time this can run before getting logged out
      await new Promise((r) => setTimeout(r, 1000));

      /* it's ok if an iteration of this loop fails. We will just try again */
      try {
        newStatus = await hsi.handleCmd('get_status', null);
      } catch (err) {
        continue;
      }

      /* see if we have a new last checkin time */
      if (oldCheckin !== newStatus.timeOfLastCheckin) {
        setIsUploadingRecording(false);
        setBanner(true, "Upload was successful!");
        return await getRecording();
      }
    }

    setIsUploadingRecording(false);
    setBanner(false, "Upload timed out. Please try recording again");
    return;
  }

  const getRecording = async () => {
    // @todo discuss using contexts vs local storage
    let seatUserId = localStorage.getItem('casana-patient-id');
    let seatId = localStorage.getItem('casana-seat-id');

    if (!seatUserId || !seatId) {
      setBanner(false, 'Please select a patient and seat from Patient Details before initializing.')
      setSitSessionData([]);
    }

    initService.getLatestInitRecording(Number(seatId), Number(seatUserId)).then(async (response: any) => {

      if (!response.success) {
        console.error(response);
        setBanner(false, 'Unable to retrieve latest initialization recording');
      }

      let sitSession = response.data;

      init.data.sit_session_id = sitSession.sit_session_id;
      init.data.raw_recording_filename = sitSession.raw_recording_filename;
      init.data.firmware_version = sitSession.firmware_version;
      init.data.hardware_version = sitSession.hardware_version;
      init.data.seat_user_id = sitSession.seat_user_id;
      init.data.date_of_birth = patient.data.date_of_birth;
      init.data.sit_start_time = sitSession.sit_start_time;
      init.data.height_feet = patient.data.seat_user_calibrations?.height_feet;
      init.data.height_inches = patient.data.seat_user_calibrations?.height_inches;
      init.data.sternal_notch = patient.data.seat_user_calibrations?.sternal_notch;
      handleFormScreenLink();
    }).catch((e: any) => {
      console.error(e);
      setBanner(false, 'Error getting latest initialization recording');
      return false;
    });
  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Use this recording?</IonCardTitle>
        { isUploadingRecording ? <IonProgressBar type="indeterminate"></IonProgressBar> : null }
        <IonCardSubtitle className="m-t-20 m-b-20">You may use this recording or go back to record another sit
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonGrid className="m-b-100" id="timer-wrapper">
          <IonRow>
            <IonCol size="12" size-md="6" className="ion-text-right">
              <IonButton onClick={handleCancelConfirmRecording} className="retake-recording">Record another sit</IonButton>
            </IonCol>
            <IonCol size="12" size-md="6" className="ion-text-left">
              <IonButton onClick={uploadRecording} className="upload-recording">Upload this recording</IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </>
  );
};

export default InitializeSitTimerConfirmScreen;
