import React, {createContext, useCallback, useState} from 'react';
import {InitializeData} from "../../types/InitializeData";


interface InitializeDataInterface {
  data: InitializeData,
  parseInitializationData: Function,
}

export const InitializeContext = createContext<InitializeDataInterface>({
  data: {
    seat_user_id: 0,
    bp_systolic_1: 0,
    bp_diastolic_1: 0,
    bp_systolic_2: 0,
    bp_diastolic_2: 0,
    bp_systolic_3: 0,
    bp_diastolic_3: 0,
    sternal_notch: 0,
    height_feet: 0,
    height_inches: 0,
    weight: 0,
    date_of_birth: '',
    sex_assigned_at_birth: '',
    age: 0,
    casana_algorithm_version: '',
    firmware_version: '',
    raw_recording_filename: '',
    hardware_version: '',
    sit_session_id: 0,
    sit_start_time: '',
    errors: []
  },
  parseInitializationData: (initializationData: InitializeData) => {}
});

export const InitializationProvider = ({ children } : React.HTMLAttributes<Element>) => {
  let [initializeData] = useState<InitializeData>({
    seat_user_id: 0,
    bp_systolic_1: 0,
    bp_diastolic_1: 0,
    bp_systolic_2: 0,
    bp_diastolic_2: 0,
    bp_systolic_3: 0,
    bp_diastolic_3: 0,
    sternal_notch: 0,
    height_feet: 0,
    height_inches: 0,
    weight: 0,
    date_of_birth: '',
    sex_assigned_at_birth: '',
    age: 0,
    casana_algorithm_version: '0.11.0',
    firmware_version: '',
    raw_recording_filename: '',
    hardware_version: '',
    sit_session_id: 0,
    sit_start_time: '',
    errors: []
  });

  const parseInitializationData = (data: InitializeData) => {
    if(data.seat_user_id) {
      initializeData.seat_user_id = data.seat_user_id
    }

    if(data.bp_systolic_1) {
      initializeData.bp_systolic_1 = data.bp_systolic_1
    }

    if(data.bp_diastolic_1) {
      initializeData.bp_diastolic_1 = data.bp_diastolic_1
    }

    if(data.bp_systolic_2) {
      initializeData.bp_systolic_2 = data.bp_systolic_2
    }

    if(data.bp_diastolic_2) {
      initializeData.bp_diastolic_2 = data.bp_diastolic_2
    }

    if(data.bp_systolic_3) {
      initializeData.bp_systolic_3 = data.bp_systolic_3
    }

    if(data.bp_diastolic_3) {
      initializeData.bp_diastolic_3 = data.bp_diastolic_3
    }

    if(data.sternal_notch) {
      initializeData.sternal_notch = data.sternal_notch
    }

    if(data.height_feet) {
      initializeData.height_feet = data.height_feet
    }

    if(data.height_inches) {
      initializeData.height_inches = data.height_inches
    }

    if(data.weight) {
      initializeData.weight = data.weight
    }

    if(data.date_of_birth) {
      initializeData.date_of_birth = data.date_of_birth
    }

    if(data.sex_assigned_at_birth) {
      initializeData.sex_assigned_at_birth = data.sex_assigned_at_birth
    }

    if(data.age) {
      initializeData.age = data.age
    }

    if(data.casana_algorithm_version) {
      initializeData.casana_algorithm_version = data.casana_algorithm_version
    }

    if(data.firmware_version) {
      initializeData.firmware_version = data.firmware_version
    }

    if(data.raw_recording_filename) {
      initializeData.raw_recording_filename = data.raw_recording_filename
    }

    if(data.hardware_version) {
      initializeData.hardware_version = data.hardware_version
    }

    if(data.sit_session_id) {
      initializeData.sit_session_id = data.sit_session_id
    }

    if(data.sit_start_time) {
      initializeData.sit_start_time = data.sit_start_time
    }

    if(data.errors) {
      initializeData.errors = data.errors
    }
  }

  const contextValue = {
    data: initializeData,
    parseInitializationData: useCallback((data: InitializeData) => parseInitializationData(data), [])
  };


  return (
    <InitializeContext.Provider value={contextValue}>{
      children}
    </InitializeContext.Provider>
  );
};
