import { createContext } from "react";
import {PairData} from "../../types/PairData";

const PairContext = createContext<PairData>({
  firmware_version: 0,
  serial_number: '',
  ble_auth_token: ''
});

export default PairContext;
