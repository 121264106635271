import './PairScreens.css';
import hsi from "../../lib/HeartSeatInterface";
import React, {useContext, useState} from "react";
import {Image} from "../../types/Image";
import {searchOutline, chevronForwardOutline} from "ionicons/icons";
import PairContext from "./PairContext";
import {PairData} from "../../types/PairData";

import {
  IonButton, IonInput, IonImg,
  IonAccordionGroup, IonAccordion, IonItem,
  IonLabel, IonGrid, IonRow, IonCol, IonIcon,
  IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCardContent, IonProgressBar
} from '@ionic/react';

import {Token} from "../../types/Token";
import AuthContext from "../../components/Auth/AuthContext";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import {useHistory} from "react-router-dom";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import useModalError from "../Modal/ModalHook";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";
import useSeatLog from "../SeatSettings/HeartSeatLogHook";

interface ContainerProps {
  handlePinView: Function,
  toggleDebug: Function
}

const PairSerialScreen: React.FC<ContainerProps> = ({toggleDebug, handlePinView}) => {
  const hsl = useSeatLog();
  const [serialNumber, setSerialNumber] = useState<string>('');
  const pairContext = useContext<PairData>(PairContext);
  const seatSettings = useSeatSettings();
  const [isPairing, setIsPairing] = useState(false);

  const pairSeatImg: Image = {
    src: './assets/pair-seat-instructions-serial-number.png',
    text: 'Instructions for connecting to the Heart Seat'
  };

  const auth = useContext<Token>(AuthContext);
  const ApiService = new CloudApiService(auth);
  const history = useHistory();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const bluetoothErrorhandler = useModalError();

  /**
   * Set the serial number stateful variable. This way when
   * it changes the state is recompiled.
   *
   * @param val
   */
  const handleSerialNumberChange = async (val: string) => {
    setSerialNumber(val);
  }

  /**
   * Trigger the native bluetooth functionality and attempt to
   * connect to the seat. If we are successful move on to the pin
   * view,
   *
   * @param serialNumber
   */
  const handleBleConnect = async (serialNumber: string) => {
    let bluetoothCtx = await hsi.getBleContextFromBrowser(serialNumber.toUpperCase());

    if (!bluetoothCtx){
      return;
    }

    setIsPairing(true);

    try {
      await hsi.connect(bluetoothCtx);
      await hsi.handleCmd('get_fw_version', null).then((response:number) => {
        pairContext.firmware_version = response;
        setIsPairing(false);
      });
      pairContext.serial_number = bluetoothCtx.name.substring(bluetoothCtx.name.indexOf('_') + 1);
      connectWithPin();
    } catch (error) {
      setIsPairing(false);
      console.error(error);
    }
  }

  /**
   * Fetch the seat pin from the cloud and connect to the seat, if we fail to
   * retrieve a pin, allow the user to enter a pin.
   */
  const connectWithPin = async () => {
    try {
      ApiService.getSeat(pairContext.serial_number).then(async (response:any)=>{

        let pin:string|null = response.ble_auth_token;

        try {
          await hsi.handleCmd('ble_auth', pin);

          await hsi.handleCmd('get_ble_auth_token', null).then((response:any) => {
            if(response){
              seatSettings.parseSettings(response);
              pairContext.ble_auth_token = response;
              hsi.unregisterAllConnectionStatusHandler();
              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();
              hsi.unregisterAllProcessEventHandlers();

              hsi.registerConnectionStatusHandler(() => {
                toggleDebug(false);
                bluetoothErrorhandler.addError(
                  'Seat Disconnected', // title
                  'You are no longer connected to a Heart Seat. Please pair to a seat in order to continue.', // content
                  'Pair Seat Again', // button text
                  '/pair' // button location
                );
              });

              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
              hsi.registerProcessEventHandler(hsl.onHsLogMsg);
              toggleDebug(true);

              hsi.unregisterAllDebugHandlers();
              hsi.unregisterAllMsgErrorHandlers();
              hsi.unregisterAllProcessEventHandlers();
              hsi.registerDebugHandler(hsl.onHsLogMsg);
              hsi.registerMsgErrorHandler(hsl.onHsLogMsg);
              hsi.registerProcessEventHandler(hsl.onHsLogMsg);

              history.push("/wifi");
              locationContext.returnView = '/wifi';
            }
          });
        } catch (error) {
          toggleDebug(false);
          console.error(error);
          handlePinView();
        }

      });

    } catch ( error ) {
      console.error(error);
      handlePinView();
    }

  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle className="m-b-20">Pair with your selected seat</IonCardTitle>
        { isPairing ? <IonProgressBar type="indeterminate"></IonProgressBar> : null }
        <IonCardSubtitle className="m-t-20">After selecting the Pair Seat button, a list of available seats will
          appear in a pop-up menu. You can filter the list of available seats by typing the first digits of your
          seat's serial number into the Filter field.</IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="standard-container-content pair-select-serial-number">
        <IonGrid>
          <IonRow>
            <IonCol size="12" size-md="4">
              <IonItem className="serial-number-input">
                <IonIcon slot="start" ios={searchOutline} md={searchOutline}></IonIcon>
                <IonInput className="serial-number-input"
                          autocapitalize="characters"
                          maxlength={16}
                          placeholder="Filter"
                          onIonChange={(e: any) => handleSerialNumberChange(e.detail.value)}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol size="12" size-md="4">
              <IonButton className="serial-number-submit" onClick={ () => handleBleConnect(serialNumber) }>
                Pair Seat
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonAccordionGroup className="pair-accordion no-ripple">
                <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
                  <IonItem slot="header" className="color-app">
                    <IonLabel className="pair-accordion-text">Show me where to find the seat serial number</IonLabel>
                  </IonItem>
                  <IonItem slot="content" lines="none">
                    <IonImg src={pairSeatImg.src} alt={pairSeatImg.text} className="ion-align-self-center seat-image"/>
                  </IonItem>
                </IonAccordion>
              </IonAccordionGroup>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </>
  );
};

export default PairSerialScreen;
