import './Initialize.css';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {IonContent, IonPage, IonText, IonCard} from '@ionic/react';
import {Token} from "../../types/Token";
import {useHistory} from "react-router-dom";
import {InitializationProvider} from "../../components/Initialize/InitializationProvider";
import InitializeIntroScreen from "../../components/Initialize/InitializeIntroScreen";
import InitializeSitTimerScreen from "../../components/Initialize/InitializeSitTimerScreen";
import InitializeExistingRecordingScreen from "../../components/Initialize/InitializeExistingRecordingScreen";
import InitializeFormScreen from "../../components/Initialize/InitializeFormScreen";
import InitializeSitTimerConfirmScreen from "../../components/Initialize/InitializeSitTimerConfirmScreen";
import InitializeFormSuccessScreen from "../../components/Initialize/InitializeFormSuccessScreen";
import InitializeFormFailureScreen from "../../components/Initialize/InitializeFormFailureScreen";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import AuthenticationService from "../../services/AuthenticationService/AuthenticationService";
import AuthContext from "../../components/Auth/AuthContext";

const Initialize: React.FC = () => {
  const history = useHistory();
  const auth = useContext<Token>(AuthContext);
  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const ApiService = new CloudApiService(auth);
  const authenticationService = new AuthenticationService(authContext);

  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Initialize your seat";
  }, []);

  const setBanner = (success: boolean, message: string) => {
    if (success) {
      setSuccess(true);
      setSuccessText(message);
      setError(false);
    } else {
      setError(true);
      setErrorText(message);
      setSuccess(false);
    }
  }

  const clearBanner = () => {
    setSuccess(false);
    setError(false);
  }

  const handleRecordSitScreenLink = () => {
    setInitializeView(
      <InitializeExistingRecordingScreen
        handleFormScreenLink={handleFormScreenLink}
        setBanner={setBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleFormScreenLink = () => {
    setInitializeView(
      <InitializeFormScreen
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleInitializationSuccess={handleInitializationSuccess}
        handleBackToStart={handleBackToStart}
        handleInitializationFailure={handleInitializationFailure}
      />
    )
  }

  const returnToInitForm = () => {
    clearBanner();
    setInitializeView(
      <InitializeFormScreen
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleInitializationSuccess={handleInitializationSuccess}
        handleBackToStart={handleBackToStart}
        handleInitializationFailure={handleInitializationFailure}
      />
    )
  }

  const handleRecordingCompletedLink = () => {
    setInitializeView(
      <InitializeSitTimerConfirmScreen
        handleFormScreenLink={handleFormScreenLink}
        handleCancelConfirmRecording={handleCancelConfirmRecording}
        setBanner={setBanner}
      />
    )
  }

  const handleSitTimerLink = () => {
    setInitializeView(
      <InitializeSitTimerScreen
        handleRecordingCompletedLink={handleRecordingCompletedLink}
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleCancelConfirmRecording = () => {
    clearBanner();
    setInitializeView(
      <InitializeSitTimerScreen
        handleRecordingCompletedLink={handleRecordingCompletedLink}
        setBanner={setBanner}
        clearBanner={clearBanner}
        handleBackToStart={handleBackToStart}
      />
    )
  }

  const handleInitializationSuccess = () => {
    setInitializeView(
      <InitializeFormSuccessScreen
        handleFinishAndLogout={handleFinishAndLogout}
        resetAndRestart={resetAndRestart}
        returnToInitForm={returnToInitForm}
      />
    )
  }

  const handleInitializationFailure = () => {
    setInitializeView(
      <InitializeFormFailureScreen
        resetAndRestart={resetAndRestart}
        returnToInitForm={returnToInitForm}
      />
    )
  }

  const handleBackToStart = () => {
    clearBanner();
    setInitializeView(
      <InitializeIntroScreen
        handleRecordSitScreenLink={handleRecordSitScreenLink}
        handleSitTimerLink={handleSitTimerLink}
      />
    )
  }

  /**
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, and return to start
   */
  const resetAndRestart = async () => {
    clearBanner();
    let seatId = localStorage.getItem('casana-seat-id');

    if (seatId) {
      await ApiService.enableAutoRecordingAndUpload(seatId);
    }

    localStorage.removeItem('casana-patient-id');
    localStorage.removeItem('casana-seat-id');
    history.push("/pair");
    seatSettings.setButtonClass('off');
    window.location.reload();
  }

  /**
   * Clear modals, re-enable auto upload and recording for seat, remove items from local storage, then log out
   */
  const handleFinishAndLogout = async () => {

    clearBanner();
    let seatId = localStorage.getItem('casana-seat-id');

    if (seatId) {
      await ApiService.enableAutoRecordingAndUpload(seatId);
    }

    localStorage.removeItem('casana-patient-id');
    localStorage.removeItem('casana-seat-id');

    authenticationService.logout().then(()=>{
      history.push("/login");
      window.location.reload();
    });
  }

  const [initializeView, setInitializeView] = useState<ReactNode>(
    <InitializeIntroScreen
      handleRecordSitScreenLink={handleRecordSitScreenLink}
      handleSitTimerLink={handleSitTimerLink}
    />
  );

  return (
    <IonPage>
      <InitializationProvider>
        <IonContent fullscreen className="container">
          <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="danger">
              {errorText}
            </IonText>
          </IonCard>
          <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
            <IonText className="success">
              {successText}
            </IonText>
          </IonCard>
          <IonCard className="standard-container">
            {initializeView}
          </IonCard>
        </IonContent>
      </InitializationProvider>
    </IonPage>
  );
};

export default Initialize;

