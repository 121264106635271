import './PatientDetails.css';
import React, {ReactNode, useEffect, useState} from 'react';
import {IonContent, IonPage} from '@ionic/react';
import ExistingPairScreen from '../../components/PatientDetails/ExistingPairScreen';
import SeatUserId from "../../components/PatientDetails/SeatUserId";
import PatientDetailsForm from '../../components/PatientDetails/PatientDetailsForm';
import ConfirmSeatUserScreen from "../../components/PatientDetails/ConfirmSeatUserScreen";
import usePatientData from "../../components/PatientDetails/PatientHook";
import useSeatSettingsData from "../../components/SeatSettings/SeatSettingsHook";


const PatientDetails: React.FC = () => {
  const patient = usePatientData();
  const seatSettings = useSeatSettingsData();

  const handeCurrentPatient = () => {
    setPatientView(<PatientDetailsForm handleSelectIDView={handleOtherPatient}/>);
  }

  const handleOtherPatient =  () => {
    setPatientView(<SeatUserId confirmPatient={handleConfirmView} />);
  }

  const handlePatientDetails =  () => {
    setPatientView(<PatientDetailsForm handleSelectIDView={handleOtherPatient}/>);
  }

  const handleConfirmView = () => {
    setPatientView(<ConfirmSeatUserScreen handeCurrentPatient={handlePatientDetails} handleSelectView={handleSelectView}/>);
  }

  const handleSelectView = () => {
    setPatientView(<SeatUserId confirmPatient={handleConfirmView} />);
  }


  useEffect(() => {
    document.title = "Patient Details";

    if(patient.data.seat_user_id === 0 && seatSettings.settings.assignedSeatUserId === 0) {
      setPatientView(<SeatUserId confirmPatient={handleConfirmView} />);
    } else {
      setPatientView(
        <ExistingPairScreen
          handleCurrentPatient={handeCurrentPatient}
          handleOtherPatient={handleOtherPatient}
        />
      );
    }
  }, [patient.data])

  const [patientView, setPatientView] = useState<ReactNode>(
    <ExistingPairScreen handleOtherPatient={handleOtherPatient} handleCurrentPatient={handeCurrentPatient}/>
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        {patientView}
      </IonContent>
    </IonPage>
  );
};

export default PatientDetails;
