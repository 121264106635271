import React, {useContext} from "react";
import {InitializeData} from "../../types/InitializeData";
import {
  IonButton,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonFooter,
  IonLabel,
  IonItem,
  IonCol,
  IonRow,
  IonText,
  IonContent
} from '@ionic/react';

import useInitializationData from "./InitializationHook";

interface ContainerProps {
  resetAndRestart: any;
  returnToInitForm: any;
}

const InitializeFormFailureScreen: React.FC<ContainerProps> = ({resetAndRestart, returnToInitForm}) => {

  const init = useInitializationData();

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Something went wrong...</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">The initialization returned an error. If you notice a mistake
          in the parameters listed below, please select the "Back" button to re-enter the cuff values and
          re-analyze the initialization.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent>
        <IonRow>
          <IonCol size="12" size-md="6">

            <IonText className="blockquote">

              <IonLabel>
                <IonCardSubtitle className="bold">Initialization Date:</IonCardSubtitle>
              </IonLabel>
              <IonText>
                <IonCardSubtitle>{new Date(init.data.sit_start_time || '').toLocaleTimeString('en-us', {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true
                })}</IonCardSubtitle>
              </IonText>

              <IonLabel><IonCardSubtitle className="bold">Initialization Parameters:</IonCardSubtitle></IonLabel>

              <IonText>
                <IonCardSubtitle>BP1: {init.data.bp_systolic_1} / {init.data.bp_diastolic_1}</IonCardSubtitle>
                <IonCardSubtitle>BP2: {init.data.bp_systolic_2} / {init.data.bp_diastolic_2}</IonCardSubtitle>
                <IonCardSubtitle className="ion-padding-bottom">BP3: {init.data.bp_systolic_3} / {init.data.bp_diastolic_3}</IonCardSubtitle>

                <IonCardSubtitle>Sternal notch: {init.data.sternal_notch} in</IonCardSubtitle>
                <IonCardSubtitle>Height: {init.data.height_feet} ft {init.data.height_inches} in</IonCardSubtitle>
                <IonCardSubtitle>Age: {init.data.age} years</IonCardSubtitle>
                <IonCardSubtitle>Weight: {init.data.weight} kg</IonCardSubtitle>
              </IonText>
            </IonText>
          </IonCol>

          <IonCol size="12" size-md="6">

            <IonText className="blockquote">
              <IonCardSubtitle className="bold">Error(s) returned:</IonCardSubtitle>
              <IonText>{init.data.errors.join(" ")}</IonText>
            </IonText>

          </IonCol>

        </IonRow>

      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-next ion-float-right" onClick={resetAndRestart}>Skip</IonButton>
        <IonButton className="btn btn-back ion-float-right" onClick={returnToInitForm}>Back</IonButton>
      </IonFooter>
    </>
  );
};

export default InitializeFormFailureScreen;
