import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';
import Menu from './components/Menu/Menu';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Initialize from './pages/Initialize/Initialize';
import Pair from './pages/Pair/Pair';
import PatientDetails from './pages/PatientDetails/PatientDetails';
import SeatDebug from './pages/SeatDebug/SeatDebug';
import WiFi from './pages/WiFi/WiFi';
import ModalProvider from "./components/Modal/ModalProvider";
import ModalNotification from "./components/Modal/Modal";
import SeatSettingsIcon from "./components/SeatSettings/SeatSettingsIcon";
import SeatSettingsProvider from "./components/SeatSettings/SeatSettingsProvider";
import HeartSeatLogProvider from "./components/SeatSettings/HeartSeatLogProvider";
import React, {useState} from "react";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/override.css';
import './theme/container.css';
import './theme/form.css';
import {PatientProvider} from "./components/PatientDetails/PatientProvider";

setupIonicReact();

const App: React.FC = () => {
  const [debug, setDebug] = useState<boolean>(false);

  return (
    <ModalProvider>
      <ModalNotification />
      <IonApp>
        <IonReactRouter>
            <SeatSettingsProvider>
              <HeartSeatLogProvider>
                <SeatSettingsIcon display={debug}/>
                <IonSplitPane contentId="main" when="xs">
                <Menu />
                  <IonRouterOutlet id="main">
                    <Route path="/" exact={true}>
                      <Redirect to="/login" />
                    </Route>
                    <Route path="/login" exact={true}>
                      <Login />
                    </Route>
                    <Route path="/logout" exact={true} >
                      <Logout toggleDebug={setDebug}/>
                    </Route>
                    <Route path="/pair" exact={true}>
                      <Pair toggleDebug={setDebug}/>
                    </Route>
                    <Route path="/wifi" exact={true}>
                      <WiFi />
                    </Route>
                    <PatientProvider>
                      <Route path="/patient-details" exact={true}>
                        <PatientDetails />
                      </Route>
                      <Route path="/initialize" exact={true}>
                        <Initialize />
                      </Route>
                    </PatientProvider>
                    <Route path="/seat-settings" exact={true}>
                      <SeatDebug />
                    </Route>
                  </IonRouterOutlet>
              </IonSplitPane>
              </HeartSeatLogProvider>
            </SeatSettingsProvider>
        </IonReactRouter>
      </IonApp>
    </ModalProvider>
  );
};

export default App;
