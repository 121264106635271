import React, {useContext, useState} from 'react';
import AuthenticationService from '../../../services/AuthenticationService/AuthenticationService';
import './AuthenticationForm.css';
import {useHistory} from 'react-router-dom';
import {Image} from '../../../types/Image';
import AuthContext from "../../Auth/AuthContext";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from '@ionic/react';

import {
  personOutline,
  lockClosedOutline
} from 'ionicons/icons';
import useSeatSettings from "../../SeatSettings/SeatSettingsHook";

const AuthenticationForm: React.FC = () => {

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const seatSettings = useSeatSettings();
  const auth = new AuthenticationService(authContext);
  const casanaLogo: Image = {src: './assets/casana-logo.svg', text: 'Casana'};

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [statusText, setStatusText] = useState<string>('Please sign into your account');

  //Submit the form data once the button is clicked
  const handleFormSubmit = async () => {
    auth.login(email, password).then((response: any) => {
      if (!response.success) {
        setStatusText(response.data.errors);
        return;
      }

      if (!response.data.jwt.length) {
        setStatusText('An unknown login error occurred. Please try again.');
        return;
      }

      // If the authentication service returns a jwt, send the user to the pair page
      localStorage.setItem('casana-api-jwt', response.data.jwt);
      authContext.jwt = response.data.jwt
      history.push('/pair');
      seatSettings.setButtonClass('off');
    });
  }

  //Two-way binding for the email field
  const handleEmailChange = async (val: string) => {
    setEmail(val);
  }

  //Two-way binding for the password field
  const handlePasswordChange = async (val: string) => {
    setPassword(val);
  }

  return (
    <IonContent className="authentication-form">
      <IonImg src={casanaLogo.src} alt={casanaLogo.text} className='casana-logo'></IonImg>
      <IonCard className="authentication-form-text">
        <IonCardHeader>
          <IonCardTitle>Welcome</IonCardTitle>
          <IonCardSubtitle>{statusText}</IonCardSubtitle>
        </IonCardHeader>
      </IonCard>
      <IonList className="authentication-form">
        <IonItem>
          <IonLabel>
            <IonIcon slot="start" ios={personOutline} md={personOutline} />
          </IonLabel>
          <IonInput type="email" onIonChange={(e: any) => handleEmailChange(e.detail.value)}></IonInput>
        </IonItem>
        <IonItem>
          <IonLabel>
            <IonIcon slot="start" ios={lockClosedOutline} md={lockClosedOutline} />
          </IonLabel>
          <IonInput type="password" onIonChange={(e: any) => handlePasswordChange(e.detail.value)}></IonInput>
        </IonItem>
        <IonText className="ion-text-center">
          <p>
            <a href="https://cloud.casanacare.com/reset-password"
                target="_blank"
                rel="noreferrer"
                className="forgot-password"
            >
              Forgot your password?
            </a>
          </p>
        </IonText>
        <IonButton expand="block" onClick={handleFormSubmit}>Login</IonButton>
      </IonList>
    </IonContent>
  );
};

export default AuthenticationForm;
