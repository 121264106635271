import './SeatDebug.css';
import React, {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import useSeatSettings from "../../components/SeatSettings/SeatSettingsHook";
import PairContext from "../../components/Pair/PairContext";
import hsi from "../../lib/HeartSeatInterface";
import moment from "moment";
import {
  IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader,
  IonItem, IonPage, IonRow, IonText, IonTitle
} from '@ionic/react';
import useSeatLog from "../../components/SeatSettings/HeartSeatLogHook";

const SeatDebug: React.FC = () => {
  const history = useHistory();
  const pairContext = useContext(PairContext);
  const seatSettings = useSeatSettings();
  const hsl = useSeatLog();
  let logContainer = document.getElementById("log-container");

  /**
   * Make sure that all the log handlers are only registered once. Multiple handlers will cause duplicated log lines.
   * If we are not in the process of loading the page then just register the handlers. If we are in the process of
   * loading the page then create a callback to check connection to the seat. This makes sure the user is redirected
   * away from the debug page only on page reloads, as the user would not be connected to a seat and this page would be
   * broken.
   */
  useEffect(() => {
    if (document.readyState === 'complete') {
      hsi.registerDebugHandler(scrollLogView);
      hsi.registerMsgErrorHandler(scrollLogView);
      hsi.registerProcessEventHandler(scrollLogView);
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, [hsl.logData]);

  /**
   * This is used to trigger the check to seat connection only on fresh page loads. This allows us to redirect away
   * from the seat settings page if the user is not connected to a seat when the page loads. Otherwise, if the wizard
   * loses connection to the seat the background process will pick it up and show the modal that redirects the user to
   * the pair screen correctly.
   */
  const onPageLoad = () => {
    checkSeatConnection();
  };

  /**
   * Makes sure the log view is always showing the latest log actions.
   */
  const scrollLogView = () => {
    if(logContainer){
      logContainer.scrollTop = logContainer.scrollHeight;
    }
  }

  /**
   * Force the seat to check in with the cloud URL. Can optionally also upload all pending recordings.
   *
   * @param doUpload
   */
  const forceCheckinWaitImpl = async (doUpload: boolean) => {
    /* get the most up-to-date last checkin time */
    let newStatus = await hsi.handleCmd('get_status', null);
    const oldCheckin = newStatus.timeOfLastCheckin;

    /* tell the seat to check in at the next available opportunity (async) */
    if (doUpload) await hsi.handleCmd('checkin_with_upload', null);
    else await hsi.handleCmd('checkin', null);

    /* poll the last checkin time to see if we have had a successfully checkin */
    for (let i = 0; i < 45; i++) {
      await new Promise((r) => setTimeout(r, 1000));

      /* it's ok if an iteration of this loop fails. We will just try again */
      try {
        newStatus = await hsi.handleCmd('get_status', null);
      } catch (err) {
        continue;
      }

      /* see if we have a new last checkin time */
      if (oldCheckin !== newStatus.timeOfLastCheckin) {
        return;
      }
    }

    /* We timed out. Throw an error */
    throw new Error('checkin timed out');
  };

  /**
   * Allow us to move the user away from the settings page if a seat is not connected.
   */
  const checkSeatConnection = () => {
    hsi.handleCmd('file_get_info', "user_cfg").catch((e) => {
      if(e.toString() === 'Error: not connected'){
        history.push('/pair');
        seatSettings.setButtonClass('off');
      } else {
        console.error(e);
      }
    });
  }

  /**
   * Disconnect from the seat over BLE
   **/
  const disconnect = async () => {
    hsl.onUserAction('seat disconnect');
    hsi.disconnect();
  };

  /**
   * User acton to force the seat to check in with the cloud.
   */
  const forceCheckIn = async () => {
    hsl.onUserAction('checkin');
    await forceCheckinWaitImpl(false);
  }

  /**
   * User action to force the seat to upload all pending recordings to the cloud.
   */
  const forceUpload = async () => {
    hsl.onUserAction('checkin with recording upload');
    await forceCheckinWaitImpl(true);
  }

  /**
   * User action to reboot the seat.
   */
  const reboot = async () => {
    hsl.onUserAction('seat reboot');
    await hsi.handleCmd('software_reset', null);
    await new Promise((r) => setTimeout(r, 3000));
  };

  /**
   * Clear the log container.
   */
  const clear = async () => {
    hsl.setLogData([]);
  }

  /**
   * Copy all text in the log container to the clipboard.
   */
  const copy = () => {
    const data = assembleLogData();
    navigator.clipboard.writeText(data);
    hsl.onUserAction('copy logs to clipboard');
  }

  /**
   * Convenience method for converting the log to a string
   **/
  const assembleLogData = () => {
    return hsl.logData.map((entry: any) => `${hsl.getLogLineString(entry)}\n`).join('');
  }

  /**
   * Download the contents of the log container as a file.
   */
  const save = () => {
    hsl.onUserAction('log file download');
    const filename = `${moment().format('YYYY-MM-DD-HH-mm-s')}_hslog.txt`;
    const type = 'text/plain';
    const data = assembleLogData();

    const file = new Blob([data], { type: type });
      const a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="seat-debug">
        <IonCard>
        <IonHeader className="seat-debug-header">
            <IonTitle size="large">Seat details and debug log</IonTitle>
        </IonHeader>
        <IonItem lines="none">
          <IonGrid className="seat-options">
            <IonRow>
              <IonButton className="btn btn-type-code" expand="block" onClick={disconnect}>Disconnect Seat</IonButton>
              <IonButton className="btn btn-type-code" expand="block" onClick={forceCheckIn}>Force Checkin</IonButton>
              <IonButton className="btn btn-type-code" expand="block" onClick={forceUpload}>Force Upload</IonButton>
              <IonButton className="btn btn-type-code" color="danger" expand="block" onClick={reboot}>Reboot</IonButton>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem lines="none">
          <IonGrid className="seat-data">
            <IonRow>
              <IonCol>Serial Number</IonCol>
              <IonCol>{pairContext.serial_number}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Firmware Version</IonCol>
              <IonCol>{seatSettings.settings.firmwareVersion}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Last Checkin (Local Time)</IonCol>
              <IonCol>{seatSettings.settings.timeOfLastCheckin}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Last Recording (Local Time)</IonCol>
              <IonCol>{seatSettings.settings.timeOfLastRecording}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Battery Voltage</IonCol>
              <IonCol>{seatSettings.settings.batteryVoltage}</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>Cloud Endpoint</IonCol>
              <IonCol>{seatSettings.settings.cloud_endpoint}</IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
        <IonItem lines="none">
          <IonGrid className="seat-log">
            <IonRow>
              <IonTitle>Log</IonTitle>
              <IonButton className="btn btn-type-code" expand="block" onClick={clear} >Clear</IonButton>
              <IonButton className="btn btn-type-code" expand="block" onClick={save} >Save</IonButton>
              <IonButton className="btn btn-type-code" expand="block" onClick={copy} >Copy</IonButton>
            </IonRow>
            <IonRow id="log-container" className='log-data'>
              {hsl.logData.map((entry:any, i:number) => (
                <IonText key={i} className={hsl.getLogLineClassName(entry)}>
                  {hsl.getLogLineString(entry)}
                </IonText>
              ))}
            </IonRow>
          </IonGrid>
        </IonItem>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default SeatDebug;
