import * as rs from 'restructure';
import StructDef from './StructDef';

/* Struct / magic definitions from the C code */
export const BLE_FRAME_DEF = new StructDef(new rs.Struct({ magic: rs.uint16le, len: rs.uint16le }));
export const MSG_HEADER_DEF = new StructDef(
  new rs.Struct({ magic: rs.uint16le, len: rs.uint16le, type_magic: rs.uint16le, pad: rs.uint16le })
);
export const MSG_FOOTER_DEF = new StructDef(new rs.Struct({ magic: rs.uint16le, pad: rs.uint16le, crc: rs.uint32le }));
export const PROC_BEGIN_HEADER_DEF = new StructDef(new rs.Struct({ proc_magic: rs.uint16le }));
export const PROC_END_HEADER_DEF = new StructDef(new rs.Struct({ proc_magic: rs.uint16le, error: rs.uint16le, len: rs.uint16le }));
export const DBG_HEADER_DEF = new StructDef(new rs.Struct({ level: rs.uint16le, len: rs.uint16le }));
export const DBG_V2_HEADER_DEF = new StructDef(
  new rs.Struct({ version: rs.uint16le, level: rs.uint16le, len: rs.uint16le, timestamp: new rs.String(32) })
);
export const RSP_HEADER_DEF = new StructDef(new rs.Struct({ cmd: rs.uint16le, seq: rs.uint16le, len: rs.uint16le, error: rs.uint16le }));
export const CMD_HEADER_DEF = new StructDef(new rs.Struct({ cmd: rs.uint16le, seq: rs.uint16le, len: rs.uint16le, pad: rs.uint16le }));

export const BLE_FRAME_MAGIC = 0x4c42; /* 'BL' */
export const MSG_HEADER_MAGIC = 0x534d; /* 'MS' */
export const MSG_FOOTER_MAGIC = 0x4d53; /* 'SM' */
export const CMD_MAGIC = 0x4e46; /* 'FN' */
export const RSP_MAGIC = 0x5352; /* 'RS' */
export const PROC_BEGIN_MAGIC = 0x4250; /* 'PB' */
export const PROC_END_MAGIC = 0x4550; /* 'PE' */
export const DBG_MAGIC = 0x4244; /* 'DB' (deprecated, still used by old bootloaders) */
export const DBG_MAGIC_V2 = 0x3244; /* 'D2' */

export const PROC_RECORDING_MAGIC = 0x4552; /* 'RE' */
export const PROC_CHECKIN_MAGIC = 0x4943; /* 'CI' */
export const PROC_FW_DOWNLOAD_MAGIC = 0x4446; /* 'FD' */
export const PROC_FW_CHECK_MAGIC = 0x4346; /* 'FC' */

/* Constants */
export const FW_CHUNK_SIZE = 256;

/* Names of firmware images in order */
export const FW_IMAGE_NAMES = ['wifi', 'cert', 'ble_sd', 'ble', 'ppg', 'mcu', 'cksum'];

/* Firmware versions where features were introduced */
export const FW_VERSION_BLE_AUTH = '0.21.0';
