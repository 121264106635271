import {
  IonContent, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
  IonText, IonAccordionGroup, IonAccordion, IonLabel, IonItem, IonRow, IonCol,
  IonInput, IonNote, IonGrid, IonIcon, IonProgressBar
} from '@ionic/react';

import {chevronForwardOutline, eye,} from 'ionicons/icons';
import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import CloudApiService from "../../services/CloudApiService/CloudApiService";
import AuthContext from "../Auth/AuthContext";
import PairContext from "../Pair/PairContext";
import hsi from "../../lib/HeartSeatInterface";
import {Token} from "../../types/Token";
import {PairData} from "../../types/PairData";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";

const WifiScreen: React.FC = () => {
  const history = useHistory();
  const auth = useContext<Token>(AuthContext);
  const pairContext = useContext<PairData>(PairContext);
  const locationContext = useContext<LocationData>(AppLocationContext);
  const seatSettings = useSeatSettings();
  const ApiService = new CloudApiService(auth);
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [warningText, setWarningText] = useState<string>('');
  const [warning, setWarning] = useState<boolean>(false);
  const [isNetworkValid, setNetworkIsValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);
  const [isCloudUrlValid, setIsCloudUrlValid] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSettingConfiguration, setIsSettingConfiguration] = useState(false);

  /**
   * We need the empty array for deps here to prevent the hook from recalling getExistingConfig on every re-render.
   */
  useEffect(() => {
    getExistingConfig();
  }, []);

  /**
   * Retrieve any existing configuration from the heart seat and autofill it into the form.
   */
  const getExistingConfig = () => {
    setIsSettingConfiguration(true);
    setWarningText('Attempting to load existing configuration from seat.');
    setWarning(true);

    hsi.handleCmd('file_get_info', "user_cfg").then(async (user_cfg: any) => {

      let data = {
        "path": "user_cfg",
        "index": 0,
        "bytes": user_cfg.fsize
      }

      hsi.handleCmd('get_status').then(async (response: any) => {
        seatSettings.parseSettings(response);
      }).catch((e) => {
        console.error(e);
        setIsSettingConfiguration(false);
        setWarning(false);
      });

      hsi.handleCmd('file_read_raw', data).then(async (response: any) => {
        let decoder = new TextDecoder();
        let decodedData = JSON.parse(decoder.decode(response));
        seatSettings.parseSettings(decodedData);
        setSuccess(true);
        setError(false);
        setWarning(false);
        setSuccessText('Existing configuration was loaded from the Heart Seat!');
        setIsSettingConfiguration(false);
      }).catch((error) => {
        console.error(error);
        setIsSettingConfiguration(false);
        setWarning(false);
      });
    }).catch((e) => {
      if(e.message === "not connected" || e.message === "BLE disconnected") {
        setError(true);
        setSuccess(false);
        setWarning(false);
        setErrorText('Setup Wizard is not connected to a Heart Seat.')
      } else {
        console.error(e);
      }

      setIsSettingConfiguration(false);
    }).catch((e) => {
      console.error(e);
      setWarning(false);
      setIsSettingConfiguration(false);
    });
  }

  /**
   * Set the network name value and show error states appropriately.
   *
   * @param networkName
   */
  const handleNetworkChange = (networkName: string) => {
    if (networkName) {
      setNetworkIsValid(true);
      setError(false);
      seatSettings.settings.wifi_ssid = networkName;
    } else {

      setNetworkIsValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Network name is invalid.');

      if(networkName.length === 0){
        seatSettings.settings.wifi_ssid='';
      }

    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Set the cloud URL value and show error states appropriately.
   *
   * @param url
   */
  const handeCloudUrlChange = (url: string) => {
    if (url.match('^[\\w\\d-]{2,63}$')) {
      setIsCloudUrlValid(true);
      setError(false);
      seatSettings.settings.cloud_endpoint = url;
    } else {
      setIsCloudUrlValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Cloud URL is invalid.');

      if(url.length <= 2){
        seatSettings.settings.cloud_endpoint = url;
        if(url.length === 0){
          seatSettings.settings.cloud_endpoint='';
        }
      }
    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Set the Wi-Fi Network password value and show error states appropriately.
   *
   * @param password
   */
  const handlePasswordChange = (password: string) => {
    if (password && password.length > 3) {
      setIsPasswordValid(true);
      setError(false);
      seatSettings.settings.wifi_pass = password;
    } else {
      setIsPasswordValid(false);
      setError(true);
      setSuccess(false);
      setErrorText('Password is invalid.');

      if(password.length <= 3){
        seatSettings.settings.wifi_pass = password;
        if(password.length === 0){
          seatSettings.settings.wifi_pass='';
        }
      }
    }

    if (isNetworkValid && isPasswordValid && isCloudUrlValid) {
      setError(false);
    }
  }

  /**
   * Toggle for password field.
   */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  /**
   * Handle sending the user back to the pair screen.
   */
  const handleBack = async () => {
    setErrorText('');
    setError(false);
    setSuccess(false);
    setIsSettingConfiguration(false);
    history.push('/pair');
    locationContext.returnView = '/pair'
    seatSettings.setButtonClass('off');
  }

  /**
   * If we have valid data for the Wi-Fi configuration, make an API call to the cloud and disable auto_recording and
   * auto_upload. If that is successful, attempt to update the configuration on the seat. Once we have updated the
   * config, retrieve the UserConfig from teh seat, and verify it matches what we attempted to set. Once we
   * can verify the seat has the correct config, perform a checkin and make sure that it completes successfully before
   * we send the user to the Patient Details screen.
   */
  const handleNext = async () => {
    /**
     * Need to trigger the change events here to make sure the values are appropriately updated as we check them.
     */
    handleNetworkChange(seatSettings.settings.wifi_ssid);
    handlePasswordChange(seatSettings.settings.wifi_pass);
    handeCloudUrlChange(seatSettings.settings.cloud_endpoint);

    if (
      isNetworkValid &&
      isPasswordValid &&
      isCloudUrlValid &&
      seatSettings.settings.wifi_pass !== '' &&
      seatSettings.settings.wifi_ssid !== ''
    ) {
      setErrorText('');
      setError(false);
      setIsSettingConfiguration(true);

      try {
        ApiService.getSeat(pairContext.serial_number).then(async (response: any) => {
          if (response.seat_id) {
            // update the seat settings with the API response.
            seatSettings.parseSettings(response);
            localStorage.setItem('casana-seat-id', response.seat_id);
            localStorage.setItem('casana-patient-id', response.assigned_seat_user_id ?? 0);
            ApiService.disableAutoRecordingAndUpload(response.seat_id).then(async () => {
              let wifi_config = {
                "wifi_ssid": seatSettings.settings.wifi_ssid,
                "wifi_pass": seatSettings.settings.wifi_pass,
                "cloud_endpoint": seatSettings.settings.cloud_endpoint + '.casanacare.com',
              };

              hsi.handleCmd('set_user_config', wifi_config).then(async () => {
                hsi.handleCmd('file_get_info', "user_cfg").then(async (user_cfg: any) => {

                  let data = {
                    "path": "user_cfg",
                    "index": 0,
                    "bytes": user_cfg.fsize
                  }

                  hsi.handleCmd('file_read_raw', data).then(async (response: any) => {
                    /**
                     * The seat returns an array of bytes that we need to decode into actual data.
                     */
                    let decodedData = JSON.parse(new TextDecoder().decode(response));

                    if (
                      decodedData.wifi_ssid === seatSettings.settings.wifi_ssid &&
                      decodedData.wifi_pass === seatSettings.settings.wifi_pass &&
                      decodedData.cloud_endpoint === seatSettings.settings.cloud_endpoint + '.casanacare.com'
                    ) {
                      let checkinSuccessful = await forceCheckinWaitImpl();

                      if(checkinSuccessful) {
                        setIsSettingConfiguration(false);
                        setWarning(false)
                        setSuccess(false)
                        setError(false);
                        setErrorText('');
                        setWarningText('');
                        setSuccessText('');
                        history.push('/patient-details')
                        locationContext.returnView = '/patient-details';
                        seatSettings.setButtonClass('off');
                      } else {
                        setError(true);
                        setSuccess(false);
                        setErrorText('Cloud Checkin failed.');
                        setIsSettingConfiguration(false);
                      }
                    } else {
                      setError(true);
                      setErrorText('There was an error updating WiFi configuration on the seat.');
                      setIsSettingConfiguration(false);
                    }
                  })
                })
              }).catch((e: any) => {
                if(e.message === "not connected") {
                  console.error(e);
                  history.push('/pair');
                } else {
                  console.error(e);
                }
              })
            })
          }
        });
      } catch (e) {
        setErrorText('There was an error setting seat configuration in the cloud.');
        setError(true);
        setSuccess(false);
        setIsSettingConfiguration(false);
        console.error(e);
      }
    } else {
      setError(true);
      setSuccess(false);
      setIsSettingConfiguration(false);
    }

    if (!isPasswordValid || !isNetworkValid || !isCloudUrlValid) {
      setErrorText('Please resolve the incorrect fields below.');
      setIsSettingConfiguration(false);
    }
  }

  /**
   * Ask the seat to check in with the cloud and wait for a successful checkin to complete. This is lifted directly
   * from the Connect App.
   **/
  const forceCheckinWaitImpl = async () => {

    /* get the most up-to-date last checkin time */
    let newStatus = await hsi.handleCmd('get_status', null);
    const oldCheckin = newStatus.timeOfLastCheckin;

    /* tell the seat to check in at the next available opportunity (async) */
    await hsi.handleCmd('checkin_with_upload', null);

    /* poll the last checkin time to see if we have had a successfully checkin */
    for (let i = 0; i < 30; i++) {
      await new Promise((r) => setTimeout(r, 1000));

      /* it's ok if an iteration of this loop fails. We will just try again */
      try {
        newStatus = await hsi.handleCmd('get_status', null);
      } catch (err) {
        continue;
      }

      /* see if we have a new last checkin time */
      if (oldCheckin !== newStatus.timeOfLastCheckin) {
        console.debug("Checkin was successful!");
        return true;
      }
    }

    console.debug("Checkin was timed out.");
    return false;
  }

  return (
    <IonContent className="container">
      <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
        <IonText className="danger">
          {errorText}
        </IonText>
      </IonCard>
      <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
        <IonText className="success">
          {successText}
        </IonText>
      </IonCard>
      <IonCard className={warning ? 'ion-show flash-message' : 'ion-hide flash-message'}>
        <IonText className="warning">
          {warningText}
        </IonText>
      </IonCard>
      <IonCard className="standard-container casana-form">
        <IonCardHeader>
          <IonCardTitle>Connect to WiFi</IonCardTitle>
          { isSettingConfiguration ? <IonProgressBar type="indeterminate"></IonProgressBar> : null }
        </IonCardHeader>
        <IonCardContent className="wifi-page">
          <IonGrid className="wifi-form casana-form">
            <IonRow>
              <IonCol size="12" size-md="6">
                <IonLabel>WiFi Network Name</IonLabel>
                <IonItem className={`${isNetworkValid && 'ion-valid'} ${!isNetworkValid && 'ion-invalid'}`}>
                  <IonInput
                    required={true}
                    autofocus={true}
                    autocomplete={"off"}
                    inputmode={"text"}
                    maxlength={255}
                    debounce={300}
                    type="text"
                    placeholder="Network"
                    onIonChange={(e: any) => handleNetworkChange(e.detail.value)}
                    value={seatSettings.settings.wifi_ssid}
                    className="network-name"
                  />
                  <IonNote slot="error">Network is a required field.</IonNote>
                </IonItem>
              </IonCol>
              <IonCol size="12" size-md="6">
                <IonLabel>Password</IonLabel>
                <IonItem className={`${isPasswordValid && 'ion-valid'} ${!isPasswordValid && 'ion-invalid'}`}>
                  <IonIcon slot="end" ios={eye} md={eye} onClick={() => toggleShowPassword()}/>
                  <IonInput
                    required={true}
                    inputmode={"text"}
                    autocomplete={"off"}
                    autocorrect={"off"}
                    maxlength={255}
                    debounce={300}
                    type="password"
                    placeholder="Password"
                    onIonChange={(e: any) => handlePasswordChange(e.detail.value)}
                    value={seatSettings.settings.wifi_pass}
                    className={showPassword ? 'ion-hide network-password' : 'ion-show network-password'}

                  />
                  <IonInput
                    required={true}
                    inputmode={"text"}
                    autocomplete={"off"}
                    autocorrect={"off"}
                    maxlength={255}
                    debounce={300}
                    type="text"
                    placeholder="Password"
                    onIonChange={(e: any) => handlePasswordChange(e.detail.value)}
                    value={seatSettings.settings.wifi_pass}
                    className={showPassword ? 'ion-show cloud-url' : 'ion-hide cloud-url'}
                  />
                  <IonNote slot="error">Password is a required field.</IonNote>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonAccordionGroup className="pair-accordion no-ripple">
            <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
              <IonItem slot="header" className="color-app">
                <IonLabel className="pair-accordion-text">Advanced Options</IonLabel>
              </IonItem>
              <IonGrid slot="content" className="casana-form">
                <IonRow>
                  <IonCol size="4" size-md="4" className="ion-margin-start">
                    <IonLabel>Cloud Endpoint</IonLabel>
                    <IonItem className={`${isCloudUrlValid && 'ion-valid'} ${!isCloudUrlValid && 'ion-invalid'}`}>
                      <IonInput
                        required={true}
                        inputmode={"text"}
                        autocomplete={"off"}
                        autocorrect={"off"}
                        maxlength={255}
                        type="text"
                        placeholder="URL"
                        onIonChange={(e: any) => handeCloudUrlChange(e.detail.value)}
                        value={seatSettings.settings.cloud_endpoint}
                      />
                      <IonNote slot="error">Cloud endpoint is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="4" size-md="4" className="d-flex">
                    <IonText className="ion-align-self-end cloud-endpoint-text">.casanacare.com</IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonAccordion>
          </IonAccordionGroup>
          <IonAccordionGroup className="pair-accordion no-ripple">
            <IonAccordion value="first" className="no-ripple" toggleIconSlot="start" toggleIcon={chevronForwardOutline}>
              <IonItem slot="header" className="color-app">
                <IonLabel className="pair-accordion-text">Tips for connecting to WiFi</IonLabel>
              </IonItem>
              <IonGrid slot="content">
                <IonRow>
                  <IonText className="ion-padding" >
                    If you’re having trouble connecting to WiFi, try the following:
                  </IonText>
                </IonRow>
                <IonRow>
                  <IonText>
                    <ul>
                      <li>Confirm that the network name and password are correct. These fields are case sensitive.</li>
                      <br />
                      <li>
                        Currently, seats only connect to 2.4Ghz networks. If a network is 5G, the seat will not connect.
                      </li>
                    </ul>
                  </IonText>
                </IonRow>
              </IonGrid>
            </IonAccordion>
          </IonAccordionGroup>
        </IonCardContent>
        <IonCardContent className="standard-container-footer">
          <IonButton className="btn btn-type-code" expand="block" onClick={ handleBack }>Back</IonButton>
          <IonButton className="btn btn-scan-code" expand="block" onClick={ handleNext }>Next</IonButton>
        </IonCardContent>
      </IonCard>
    </IonContent>
  );
};

export default WifiScreen;
