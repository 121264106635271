import React, { useEffect } from 'react';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonImg
} from '@ionic/react';

import AuthenticationForm from '../../components/forms/AuthenticationForm/AuthenticationForm';
import './Login.css';
import { Image } from '../../types/Image';

const Login: React.FC = () => {

  useEffect(() => {
    document.title = "Login"
  }, [])

  const loginImg: Image = { src: './assets/toilet.svg', text: 'Login' };

  return (
    <IonPage>
      <IonContent fullscreen className="login-screen">
        <IonGrid className="ion-no-padding">
            <IonRow className="ion-justify-content-center">
              <IonCol className="ion-float-left login-image desktop">
                <IonImg src={loginImg.src} alt={loginImg.text} className="toilet-seat" />
              </IonCol>
              <IonCol className="ion-float-right login-form-column ion-no-padding">
                <AuthenticationForm />
              </IonCol>
            </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
