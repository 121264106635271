import React from "react";
import "../Initialize/InitializeScreens.css";
import {IonButton, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonFooter} from '@ionic/react';

interface ContainerProps {
  handleRecordSitScreenLink: any;
  handleSitTimerLink: any;
}

const InitializeIntroScreen: React.FC<ContainerProps> = ({handleRecordSitScreenLink, handleSitTimerLink}) => {

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>Record an initialization sit</IonCardTitle>
        <IonCardSubtitle className="m-t-20 m-b-20">Initialization is a process that allows the seat to identify the
          monitored patient
          and ensure a stable blood pressure can be detected. When performing an initialization sit, the patient’s bare
          thighs should make full contact with the seat’s sensors and they should sit as still as possible.
        </IonCardSubtitle>
        <IonCardSubtitle className="m-t-20 m-b-20">During the recording, a blood pressure cuff will take 3 separate
          blood pressure recordings. These recordings will be entered after the sit has been successfully completed.
        </IonCardSubtitle>
        <IonCardSubtitle className="m-t-20 m-b-20">Once the patient is wearing the blood pressure cuff and is seated on
          the Heart Seat, please select “Next.”
        </IonCardSubtitle>
        <IonCardSubtitle className="m-t-20 m-b-20">Already have a recording to use for initialization? Great!
          Click the text below to enter cuff information for an existing sit recording.
        </IonCardSubtitle>
      </IonCardHeader>
      <IonCardContent className="standard-container-content">
        <IonCardSubtitle>
          <a className="initialize-link" onClick={handleRecordSitScreenLink}>I already have a sit recording to use for
            initialization</a>
        </IonCardSubtitle>
      </IonCardContent>
      <IonFooter className="standard-container-footer">
        <IonButton className="btn btn-next ion-float-right" onClick={handleSitTimerLink}>Next</IonButton>
      </IonFooter>
    </>
  );
};

export default InitializeIntroScreen;
