import './PatientDetailsScreens.css';
import React, {useState, useEffect, useContext} from 'react';
import {
  IonCardContent, IonCardHeader, IonCardTitle, IonGrid, IonRow,
  IonCol, IonInput, IonLabel, IonFooter, IonButton, IonItem,
  IonContent, IonNote, IonIcon, IonCard, IonText, IonProgressBar, IonSelect, IonSelectOption
} from '@ionic/react';
import {useHistory} from "react-router-dom";
import AuthContext from "../../components/Auth/AuthContext";
import PatientDetailsService from "../../services/PatientDetailsService/PatientDetailsService";
import {star} from "ionicons/icons";
import {LocationData} from "../../types/LocationData";
import AppLocationContext from "../Includes/AppLocationContext";
import usePatientData from "./PatientHook";
import moment from "moment";
import useSeatSettings from "../SeatSettings/SeatSettingsHook";

interface ContainerProps {
  handleSelectIDView: Function;
}

const PatientDetailsForm: React.FC<ContainerProps> = ({handleSelectIDView}) => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const patient = usePatientData();
  const seatSettings = useSeatSettings();
  const locationContext = useContext<LocationData>(AppLocationContext);
  const patientDetailsService = new PatientDetailsService(authContext, patient.data);
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [successText, setSuccessText] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [isSavingPatientDetails, setIsSavingPatientDetails] = useState(false);

  useEffect(() => {
      document.title = "Patient Details";

      if (patient.data.last_name === null) {
        setIsLastNameValid(false);
      }

      if (patient.data.date_of_birth === null) {
        setIsDOBValid(false);
      }

      if (patient.data.sex_assigned_at_birth === null) {
        setIsSexAssignedAtBirthValid(false);
      }

      if (patient.data.cell_phone === null) {
        setIsCellPhoneValid(false);
      }
    }, [patient.data]
  );

  /**
   * Let's check that all validation processes are successful. If they are then we should update the Cloud via the API.
   */
  const showInitialize = () => {
    if (isLastNameValid && isDOBValid && isMonitoredSinceValid) {
      setError(false);
      setIsSavingPatientDetails(true);
      patientDetailsService.updateSeatUserDetails(patient.data).then((response: any) => {
        if (!response.success) {
          setError(true);
          setSuccess(false);
          setErrorText('Error with form submission: ' + response.data.errors);
          setSuccessText('');
          setIsSavingPatientDetails(false);
          return;
        }

        setIsSavingPatientDetails(false);
        history.push('/initialize');
        locationContext.returnView = '/initialize';
        seatSettings.setButtonClass('off');
      }).catch((error:any) => {
        console.error(error);
        setIsSavingPatientDetails(false);
        setSuccess(false);
        setSuccessText('');
        setError(true);
        setErrorText('Error with form submission. Please try again later');
      });
    } else {
      setError(true);
      setSuccess(false);
      setSuccessText('');
      setErrorText('Please correct the details in the highlight fields below before continuing.')
      setIsSavingPatientDetails(false);
      window.scrollTo(0, 0);
    }
  }

  const selectUserId = () => {
    handleSelectIDView();
  }

  // Define the valid const's that will be used in the form error checking
  const [isLastNameValid, setIsLastNameValid] = useState<boolean>(true);
  const [isDOBValid, setIsDOBValid] = useState<boolean>(true);
  const [isMonitoredSinceValid, setIsMonitoredSinceValid] = useState<boolean>(true);
  const [isCellPhoneValid, setIsCellPhoneValid] = useState<boolean>(true);
  const [isStateValid, setIsStateValid] = useState<boolean>(true);
  const [isZipCodeValid, setIsZipCodeValid] = useState<boolean>(true);
  const [isSexAssignedAtBirthValid, setIsSexAssignedAtBirthValid] = useState<boolean>(true);
  const [isHeightFeetValid, setIsHeightFeetValid] = useState<boolean>(true);
  const [isHeightInchesValid, setIsHeightInchesValid] = useState<boolean>(true);
  const [isSternalNotchValid, setIsSternalNotchValid] = useState<boolean>(true);

  //Define some variables for use as validation values and limitations in form fields
  const today = new Date();
  let minDOBDate = new Date(today.getFullYear() - 100,today.getMonth() + 1, today.getDate());
  let maxDOBDate = new Date(today.getFullYear() - 15, today.getMonth() + 1, today.getDate());
  let tempMinMonitoredSince = new Date(2020, 0, 1);
  let tempMaxMonitoredSince = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const [minDateOfBirth] = useState<string>(formattedDate(minDOBDate));
  const [maxDateOfBirth] = useState<string>(formattedDate(maxDOBDate));
  const [minMonitoredSince] = useState<string>(formattedDate(tempMinMonitoredSince));
  const [maxMonitoredSince] = useState<string>(formattedDate(tempMaxMonitoredSince));

  /**
   * Convert a date provided into a time string that can be used in comparisons and for the form element.
   *
   * @param providedDate
   */
  function formattedDate(providedDate: Date) {
    return moment(providedDate).format('MM/DD/YYYY');
  }

  const handleLastNameChange = async (val: string) => {
    patient.data.last_name = val;
    if (val !== '') {
      setIsLastNameValid(true);
    } else {
      setIsLastNameValid(false);
    }
  }

  const handleDOBChange = async (val: string) => {
    patient.data.date_of_birth = val;
    let dobAsTime = new Date(val).getTime();
    if (val !== '' && (dobAsTime > minDOBDate.getTime() && dobAsTime < maxDOBDate.getTime())) {
      setIsDOBValid(true);
    } else {
      setIsDOBValid(false);
    }

  }

  const handleMonitoredSinceChange = async (val: string) => {
    patient.data.monitored_since = val;
    let monitoredSinceAsTime = new Date(val).getTime();
    if (val !== '' && (
      monitoredSinceAsTime >= tempMinMonitoredSince.getTime() &&
      monitoredSinceAsTime <= tempMaxMonitoredSince.getTime())
    ){
      setIsMonitoredSinceValid(true);
    } else {
      setIsMonitoredSinceValid(false);
    }
  }

  const handleCellPhoneChange = async (val: string) => {
    let formattedCellPhone = val ? formatPhoneNumber(val) : '';
    const parsedVal = val.replace(/[^0-9]/g, '');

    if (parsedVal && formattedCellPhone) {
      patient.data.cell_phone = formattedCellPhone;
    } else {
      patient.data.cell_phone = '';
      setIsCellPhoneValid(false);
    }

    if (parsedVal.length === 10) {
      setIsCellPhoneValid(true);
    } else {
      setIsCellPhoneValid(false);
    }
  }

  const handleHeightFeetChange = async (val: number) => {
    patient.data.seat_user_calibrations.height_feet = val;

    if(patient.data.seat_user_calibrations.height_feet < 0 || patient.data.seat_user_calibrations.height_feet > 9) {
      setIsHeightFeetValid(false);
    } else {
      setIsHeightFeetValid(true);
    }
  }

  const handleHeightInchesChange = async (val: number) => {
    patient.data.seat_user_calibrations.height_inches = val;

    if(patient.data.seat_user_calibrations.height_inches < 0 || patient.data.seat_user_calibrations.height_inches > 99) {
      setIsHeightInchesValid(false);
    } else {
      setIsHeightInchesValid(true);
    }
  }

  const handleSternalNotchChange = async (val: number) => {
    patient.data.seat_user_calibrations.sternal_notch = val;

    if(patient.data.seat_user_calibrations.sternal_notch < 18 ||
      patient.data.seat_user_calibrations.sternal_notch > 48
    ) {
      setIsSternalNotchValid(false);
    } else {
      setIsSternalNotchValid(true);
    }
  }

  /**
   * If state is enter then we should ensure that it matches an actual
   * state that is listed in the usStateOptions array.
   *
   * @param val
   */
  const handleStateChange = async (val: string) => {
    patient.data.state = val.toUpperCase();
    if (val === '' || usStateOptions.includes(val.toUpperCase())) {
      setIsStateValid(true);
    } else {
      setIsStateValid(false);
    }
  }

  /**
   * This function will return true when the field is empty or
   * has 5 digits in it (no alpha chars)
   *
   * @param val
   */
  const handleZipCodeChange = async (val: string) => {
    if (val.length === 0) {
      patient.data.zip_code = val;
      setIsZipCodeValid(true);
    } else if (val.length === 5) {
      patient.data.zip_code = val;
      setIsZipCodeValid(true);
    } else if (val.length > 5) {
      patient.data.zip_code = val.slice(0, 5);
      setIsZipCodeValid(false);
    } else {
      patient.data.zip_code = val;
      setIsZipCodeValid(false);
    }
  }

  const handleFirstNameChange = async (val: string) => {
    patient.data.first_name = val;
  }

  const handleAddressChange = async (val: string) => {
    patient.data.address = val;
  }

  const handleAddressOtherChange = async (val: string) => {
    patient.data.address_other = val;
  }

  const handleSexAssignedAtBirthChange = async (val: string) => {
    patient.data.sex_assigned_at_birth = val;
  }

  const handleCityChange = async (val: string) => {
    patient.data.city = val;
  }

  /**
   * This function will format the phone number.
   *
   * @param str
   */
  const formatPhoneNumber = (str: string) => {
    //Filter only numbers from the input
    let cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return null
  };

  const usStateOptions = [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL',
    'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH',
    'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX',
    'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  return (
    <>
      <IonContent className="container">
        <IonCard className={error ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="danger">
            {errorText}
          </IonText>
        </IonCard>
        <IonCard className={success ? 'ion-show flash-message' : 'ion-hide flash-message'}>
          <IonText className="success">
            {successText}
          </IonText>
        </IonCard>
        <IonCard className="standard-container casana-form">
          <IonCardHeader>
            <IonCardTitle>Patient Information</IonCardTitle>
            {isSavingPatientDetails ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
          </IonCardHeader>
          <IonCardContent className="standard-container-content">
            <form className="casana-form patient-details-form">
              <IonGrid className="casana-form">
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>First name</IonLabel>
                    <IonItem>
                      <IonInput
                        // required={true}
                        autofocus={true}
                        inputmode={"text"}
                        maxlength={255}
                        type="text"
                        debounce={300}
                        placeholder="Patient first name"
                        onIonChange={(e: any) => handleFirstNameChange(e.detail.value)}
                        value={patient.data.first_name}
                      />
                      <IonNote slot="error">First name is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Last name
                    </IonLabel>
                    <IonItem className={`${isLastNameValid && 'ion-valid'} ${!isLastNameValid && 'ion-invalid'}`}>
                      <IonInput
                        required={true}
                        inputmode={"text"}
                        maxlength={255}
                        type="text"
                        debounce={300}
                        placeholder="Patient last name"
                        onIonChange={(e: any) => handleLastNameChange(e.detail.value)}
                        value={patient.data.last_name}
                      />
                      <IonNote slot="error">Last name is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>

                  <IonCol size="12" size-md="6">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Date of birth
                    </IonLabel>
                    <IonItem className={`${isDOBValid && 'ion-valid'} ${!isDOBValid && 'ion-invalid'}`}>
                      <IonInput
                        required={true}
                        maxlength={255}
                        type="date"
                        debounce={300}
                        min={minDateOfBirth}
                        max={maxDateOfBirth}
                        placeholder="MM/DD/YYYY"
                        onIonChange={(e: any) => handleDOBChange(e.detail.value)}
                        value={patient.data.date_of_birth}
                      />
                      <IonNote slot="error">
                        You must enter a valid date of birth in the format MM/DD/YYYY.<br />
                        <strong>Maximum:</strong> {maxDateOfBirth}
                      </IonNote>
                    </IonItem>
                  </IonCol>

                  <IonCol size="12" size-md="6" no-padding>
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Sex assigned at birth
                    </IonLabel>
                    <IonItem className={
                      `${isSexAssignedAtBirthValid && 'ion-valid'} ${!isSexAssignedAtBirthValid && 'ion-invalid'}`
                    }>
                      <IonSelect
                        placeholder="Select sex assigned at birth"
                        value={patient.data.sex_assigned_at_birth}
                        onIonChange={(e: any) => handleSexAssignedAtBirthChange(e.detail.value)}
                      >
                        <IonSelectOption value="M">Male</IonSelectOption>
                        <IonSelectOption value="F">Female</IonSelectOption>
                      </IonSelect>
                      <IonNote slot="error">Sex assigned at birth is a required field.</IonNote>
                    </IonItem>
                  </IonCol>

                </IonRow>
                <IonRow>

                  <IonCol size="5.9" size-md="2.9">
                    <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Height</IonLabel>
                    <IonItem className={`${isHeightFeetValid && 'ion-valid'} ${!isHeightFeetValid && 'ion-invalid'}`}>
                      <IonInput
                        placeholder="ft"
                        inputmode="numeric"
                        type="text"
                        minlength={1}
                        maxlength={1}
                        min={0}
                        max={9}
                        debounce={300}
                        onIonChange={(e: any) => handleHeightFeetChange(e.detail.value)}
                        value={patient.data.seat_user_calibrations.height_feet}
                      />
                      <IonNote slot="error">Height (feet) should be between 0 ft and 9 ft</IonNote>
                      <span>ft</span>
                    </IonItem>
                  </IonCol>



                  <IonCol size="5.8" size-md="2.9">
                    <IonLabel>&nbsp;</IonLabel>
                    <IonItem className={`${isHeightInchesValid && 'ion-valid'} ${!isHeightInchesValid && 'ion-invalid'}`}>
                      <IonInput
                        placeholder="in"
                        inputmode="numeric"
                        type="text"
                        minlength={1}
                        maxlength={2}
                        min={0}
                        max={99}
                        debounce={300}
                        onIonChange={(e: any) => handleHeightInchesChange(e.detail.value)}
                        value={patient.data.seat_user_calibrations.height_inches}
                      />
                      <span>in</span>
                      <IonNote slot="error">Height (inches) is a required field and should be between 0 in and 99 in</IonNote>
                    </IonItem>
                  </IonCol>


                  <IonCol size="12" size-md="6" offset-md="0.2">
                    <IonLabel>
                      <IonIcon className="required" slot="start" ios={star} md={star}/>
                      Sternal notch
                    </IonLabel>
                    <IonItem
                      className={`${isSternalNotchValid && 'ion-valid'} ${!isSternalNotchValid && 'ion-invalid'}`}>
                      <IonInput
                        placeholder="Sternal notch (in)"
                        inputmode="numeric"
                        type="text"
                        minlength={2}
                        maxlength={5}
                        min={18}
                        max={48}
                        debounce={300}
                        onIonChange={(e: any) => handleSternalNotchChange(e.detail.value)}
                        value={patient.data.seat_user_calibrations.sternal_notch}
                      />
                      <span>in</span>
                      <IonNote slot="error">Sternal notch is a required field and should be between 18 in and 48 in</IonNote>
                    </IonItem>
                  </IonCol>



                </IonRow>
                <IonRow>

                <IonCol size="12" size-md="6">
                    <IonLabel><IonIcon className="required" slot="start" ios={star} md={star}/>Monitored
                      since</IonLabel>
                    <IonItem
                      className={`${isMonitoredSinceValid && 'ion-valid'} ${!isMonitoredSinceValid && 'ion-invalid'}`}>
                      <IonInput
                        required={true}
                        maxlength={255}
                        type="date"
                        debounce={300}
                        min={minMonitoredSince}
                        max={maxMonitoredSince}
                        onIonChange={(e: any) => handleMonitoredSinceChange(e.detail.value)}
                        value={patient.data.monitored_since}
                      />
                      <IonNote slot="error">You must enter a monitored since date in the format MM/DD/YYYY.<br/>
                        <strong>Minimum:</strong> {minMonitoredSince}<br/>
                        <strong>Maximum:</strong> {maxMonitoredSince}
                      </IonNote>
                    </IonItem>
                  </IonCol>

                  <IonCol size="12" size-md="6">
                    <IonLabel>Phone number</IonLabel>
                    <IonItem className={`${isCellPhoneValid && 'ion-valid'} ${!isCellPhoneValid && 'ion-invalid'}`}>
                      <IonInput
                        inputmode="numeric"
                        type="text"
                        placeholder="555-555-5555"
                        minlength={10}
                        maxlength={10}
                        onIonChange={(e: any) => handleCellPhoneChange(e.detail.value)}
                        value={patient.data.cell_phone}
                      />
                      <IonNote slot="error">
                        Must be 10 digits, no dashes or spaces. Do not include country code.
                      </IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" size-md="6"></IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>Address</IonLabel>
                    <IonItem>
                      <IonInput
                        placeholder="Street address or P.O Box"
                        type="text"
                        debounce={300}
                        required={true}
                        onIonChange={(e: any) => handleAddressChange(e.detail.value)}
                        value={patient.data.address}
                      />
                      <IonNote slot="error">Address is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="12" size-md="6">
                    <IonLabel>Address other</IonLabel>
                    <IonItem>
                      <IonInput
                        placeholder="Apt, Suite, Unit, Building"
                        type="text"
                        debounce={300}
                        value={patient.data.address_other}
                        onIonChange={(e: any) => handleAddressOtherChange(e.detail.value)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="12" size-md="6">
                    <IonLabel>City</IonLabel>
                    <IonItem>
                      <IonInput
                        placeholder="City"
                        type="text"
                        debounce={300}
                        value={patient.data.city}
                        onIonChange={(e: any) => handleCityChange(e.detail.value)}
                      />
                      <IonNote slot="error">City is a required field.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="5.8" size-md="2.9">
                    <IonLabel>State</IonLabel>
                    <IonItem className={`${isStateValid && 'ion-valid'} ${!isStateValid && 'ion-invalid'}`}>
                      <IonInput
                        placeholder="State"
                        type="text"
                        minlength={2}
                        maxlength={2}
                        debounce={300}
                        onIonChange={(e: any) => handleStateChange(e.detail.value)}
                        value={patient.data.state}
                      />
                      <IonNote slot="error">State is a required field should be a valid abbreviation.</IonNote>
                    </IonItem>
                  </IonCol>
                  <IonCol size="5.9" size-md="2.9">
                    <IonLabel>Zip code</IonLabel>
                    <IonItem className={`${isZipCodeValid && 'ion-valid'} ${!isZipCodeValid && 'ion-invalid'}`}>
                      <IonInput
                        placeholder="Zip Code"
                        inputmode="numeric"
                        type="number"
                        maxlength={5}
                        debounce={300}
                        onIonChange={(e: any) => handleZipCodeChange(e.detail.value)}
                        value={patient.data.zip_code}
                      />
                      <IonNote slot="error">Zip code must be 5 digits.</IonNote>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCardContent>
          <IonFooter className="standard-container-footer">
            <IonButton className="btn btn-next ion-float-right" onClick={showInitialize}>
              Next
            </IonButton>
            <IonButton className="btn btn-back ion-float-right" onClick={selectUserId}>
              Back
            </IonButton>
          </IonFooter>
        </IonCard>
      </IonContent>
    </>
  );
};

export default PatientDetailsForm;
