import React, { useEffect } from 'react';
import {IonContent, IonPage } from '@ionic/react';
import WifiScreen from '../../components/Wifi/WifiScreen';
import './WiFi.css';


const WiFi: React.FC = () => {

  useEffect(() => {
    document.title = "Connect to WiFi";
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen>
        <WifiScreen />
      </IonContent>
    </IonPage>
  );
};

export default WiFi;
